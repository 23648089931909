import React, { useEffect, useRef } from 'react';
import { Quill } from 'react-quill';

export default function RenderQuillText({ text }) {
    const quillRef = useRef(null);
    const quillInstanceRef = useRef(null);

    // This effect initializes Quill only once when the component is mounted
    useEffect(() => {
        if (quillRef.current) {
            quillInstanceRef.current = new Quill(quillRef.current, {
                theme: 'snow',
                readOnly: true,
                modules: {
                    toolbar: false,
                }
            });
        }
    }, []);

    // This effect watches the `text` prop and updates the Quill content when `text` changes
    useEffect(() => {
        if (quillInstanceRef.current && text) {
            const quillEditor = quillInstanceRef.current;
            const isEmpty = !quillEditor.root.innerHTML.trim() || quillEditor.root.innerHTML === '<p><br></p>';

            if (isEmpty) {
                quillEditor.clipboard.dangerouslyPasteHTML(0, text);  // Safely update the editor's content
            }
        }
    }, [text]);

    return <div ref={quillRef}></div>;
}
