import React, { useEffect, useState } from "react";
import {
  useDeleteDocumentMutation,
  useGetIdentificationDataQuery,
} from "../../services/identificationApi";
import { useSelector } from "react-redux";
import UploadFile from "../../components/common/uploadFile/uploadFile";
import { BiTrash } from "react-icons/bi";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { RiInformationLine } from "react-icons/ri";
import { useDocumentContext } from "../../PrivateRoute";

const ProofOfIdentity_doc_upload = () => {
  const [radio_data, setRadioData] = useState();
  const [licenseInformation, setLicenseInformation] = React.useState(false);
  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  const [deleteDocument, result] = useDeleteDocumentMutation();
  const identification = useGetIdentificationDataQuery(trn_id);
  const { documentData:documentWiseRules, sectionData:paystubWiseRules, identificationData:identificationWiseRules } = useDocumentContext();

  useEffect(() => {
    setRadioData(identification?.data?.identification_data?.documenttype_id);
  }, [identification]);
  const [open, setOpen] = useState(false);
  const deleteDocumentHandler = (id) => {
    deleteDocument(id).then((response) => { });
  };
  const [docId, setDocId] = useState("");
  //   console.log("🚀 ~ file: ProofOfIdentity_doc_upload.js ~ line 12 ~ data", data);
  const token = useSelector((state) => state.persistedReducer.loginSlice.token);
  const hasSSToken = (isImg = true) => {
    let url = identification?.data?.identification_data.file_image;
    if (!isImg) {
      url = identification?.data?.identification_data.file;
    }
    if (!url) {
      return url;
    } else if (url.includes("?")) {
      return url + "&";
    } else {
      return url;
    }
  };
  const hasSSToken1 = (isImg = true) => {
    let url = identification?.data?.identification_data.backfile;
    if (!isImg) {
      url = identification?.data?.identification_data.file;
    }
    if (!url) {
      return url;
    } else if (url.includes("?")) {
      return url + "&";
    } else {
      return url;
    }
  };
  if (identification.isLoading) return <>Loading</>;
  return (
    <div className="ei-wrap white-box">
     {(documentWiseRules?.USPermanentResidentID !="Hide" || documentWiseRules?.DriverLicense !="Hide" || documentWiseRules?.Passport!="Hide" || documentWiseRules?.Visa!="Hide" ) && <div className="ei-body">
        <div className="row">
          <div className="col-12">
            <h6> Proof of Identification:</h6>
          </div>
        </div>
        <div className="row ">
          <div className="col-sm-12">
            <div className="blue-box">
              <div className="row ">
                <>
                  <div className="col-sm-12">
                    <div className="d-md-flex justify-content-between align-items-center mr-t20">
                      <div className="ssn-text-block">
                        <h4>
                          Proof of Identification Option:
                          <button type="button" className="info-btn">
                            <img src="images/info-icon.png" alt="" />
                          </button>
                        </h4>
                        <br />
                        <ul>
                          <li>
                            <div className="r-list-radio mb-2 d-flex align-items-center">
                              <label
                                htmlFor="l1"
                                className="cus-r-btn d-flex mr-b0"
                              >
                                <input
                                  disabled={
                                    identification?.data?.identification_data
                                      .file !== null ||
                                      identification?.data?.identification_data
                                        .backfile !== null
                                      ? true
                                      : false
                                  }
                                  type="radio"
                                  id="l1"
                                  checked={radio_data == 2 || radio_data == 13}
                                  onChange={(e) => {
                                    setRadioData(2);
                                  }}
                                  name="identity-doc"
                                />
                                <span className="mr-r6"></span>
                                <div className="d-flex flex-column">
                                  <div className="d-flex">
                                    License
                                    <RiInformationLine
                                      className="ms-2 cursor-pointer"
                                      fill="#D3AF51"
                                      size={20}
                                      onClick={() => {
                                        setLicenseInformation(true);
                                      }}
                                    />
                                  </div>
                                  <small className="highlight-text-background">
                                    Click the (i) button above for details
                                  </small>
                                </div>
                              </label>
                              <button type="button" className="info-btn mr-l10">
                                <img
                                  src="images/info-icon.png"
                                  alt=""
                                  title=""
                                />
                              </button>
                            </div>
                          </li>
                          {documentWiseRules?.Passport!=="Hide"  && <li>
                            <div className="r-list-radio mb-2 d-flex align-items-center">
                              <label
                                htmlFor="passport"
                                className="cus-r-btn d-flex mr-b0"
                              >
                                <input
                                  type="radio"
                                  disabled={
                                    identification?.data?.identification_data
                                      .file !== null ||
                                      identification?.data?.identification_data
                                        .backfile !== null
                                      ? true
                                      : false
                                  }
                                  id="passport"
                                  checked={radio_data == 1}
                                  onChange={(e) => {
                                    setRadioData(1);
                                  }}
                                  name="identity-doc"
                                />
                                <span className="mr-r6"></span>
                                Passport
                              </label>
                            </div>
                          </li>}
                          {documentWiseRules?.USPermanentResidentID  !=="Hide"  &&<li>
                            <div className="r-list-radio mb-2 d-flex align-items-center">
                              <label
                                htmlFor="permanant-r"
                                className="cus-r-btn d-flex mr-b0"
                              >
                                <input
                                  disabled={
                                    identification?.data?.identification_data
                                      .file !== null ||
                                      identification?.data?.identification_data
                                        .backfile !== null
                                      ? true
                                      : false
                                  }
                                  type="radio"
                                  id="permanant-r"
                                  checked={radio_data == 10}
                                  onChange={(e) => {
                                    setRadioData(10);
                                  }}
                                  name="identity-doc"
                                />
                                <span className="mr-r6"></span>
                                Permanent Residence ID
                              </label>
                            </div>
                          </li>}
                          {documentWiseRules?.Visa!=="Hide"  &&<li>
                            <div className="r-list-radio mb-2 d-flex align-items-center">
                              <label
                                htmlFor="visa"
                                className="cus-r-btn d-flex mr-b0"
                              >
                                <input
                                  disabled={
                                    identification?.data?.identification_data
                                      .file !== null ||
                                      identification?.data?.identification_data
                                        .backfile !== null
                                      ? true
                                      : false
                                  }
                                  type="radio"
                                  id="visa"
                                  checked={radio_data == 11}
                                  onChange={(e) => {
                                    setRadioData(11);
                                  }}
                                  name="identity-doc"
                                />
                                <span className="mr-r6"></span>
                                Visa
                              </label>
                            </div>
                          </li>}
                        </ul>
                      </div>
                      <div className="ssn-btn">
                        <div className="d-md-flex justify-content-between align-items-center">
                          <div className="row">
                            <div className="col">
                              <UploadFile
                                disable={
                                  identification?.data?.identification_data
                                    .file !== null ||
                                    identification?.data?.identification_data
                                      .backfile !== null
                                    ? true
                                    : false
                                }
                                label="Upload Front"
                                className="mr-b10"
                                documentId={radio_data}
                                SourceId={1}
                              />
                            </div>
                            <div className="col">
                              {radio_data == 2 && (<>
                                <UploadFile
                                  disable={
                                    identification?.data?.identification_data
                                      .file == null
                                      ? true
                                      : false
                                  }
                                  label="Upload Back"
                                  className="mr-b10 "
                                  documentId={13}
                                  SourceId={1}
                                />
                                {identification?.data?.identification_data
                                  .file == null && <small style={{fontSize:12}} className="highlight-text-background">
                                    Upload Front Side first
                                  </small>
                                }
                              </>)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    {
                      <div className="row mt-1">
                        <div className="d-flex">
                          {identification?.data?.identification_data.file !==
                            null && (
                              <div className="d-flex flex-column justify-content-center align-items-center">
                                <img
                                  src={`${hasSSToken()}?token=${token}`}
                                  className="img-thumbnail cursor-pointer"
                                  alt=""
                                  width={150}
                                  height={150}
                                  onClick={() =>
                                    window
                                      .open(
                                        `${hasSSToken(false)}?token=${token}`,
                                        "_blank"
                                      )
                                      .focus()
                                  }
                                />
                                <button
                                  type="button"
                                  onClick={() => {
                                    setDocId(
                                      identification?.data?.identification_data
                                        .application_document_id
                                    );
                                    // setDeleteDocumentType("application_document_id");
                                    setOpen(true);
                                  }}
                                  title={`Delete`}
                                  className="btn btn-link btn-sm text-danger"
                                >
                                  <BiTrash /> Delete
                                </button>
                              </div>
                            )}

                          {identification?.data?.identification_data
                            .backfile !== null && (
                              <div className="d-flex flex-column justify-content-center align-items-center">
                                <img
                                  src={`${hasSSToken1()}?token=${token}`}
                                  className="img-thumbnail cursor-pointer"
                                  alt=""
                                  width={150}
                                  height={150}
                                  onClick={() =>
                                    window
                                      .open(
                                        `${hasSSToken1(false)}?token=${token}`,
                                        "_blank"
                                      )
                                      .focus()
                                  }
                                />
                                <button
                                  type="button"
                                  onClick={() => {
                                    setDocId(
                                      identification?.data?.identification_data
                                        .BarcodeDocumentID
                                    );
                                    setOpen(true);
                                  }}
                                  title={`Delete`}
                                  className="btn btn-link btn-sm text-danger"
                                >
                                  <BiTrash /> Delete
                                </button>
                              </div>
                            )}
                        </div>
                      </div>
                    }
                  </div>
                </>
              </div>
            </div>
          </div>
          <Dialog
            open={open}
            onClose={() => {
              setOpen(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {`Are you sure want to delete document ?`}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Warning! Once deleted cannot revert back .
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setOpen(false);
                }}
              >
                No
              </Button>
              <Button
                onClick={async () => {
                  setTimeout(() => {
                    deleteDocumentHandler(docId);
                  }, 200);
                  setOpen(false);

                  setOpen(false);
                }}
                autoFocus
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={licenseInformation}
            onClose={() => {
              setLicenseInformation(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title"></DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Valid forms of proof identity include a driver's license, US
                passport, Permanent Resident ID, or US VISA.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={async () => {
                  setLicenseInformation(false);
                }}
                autoFocus
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>}
    </div>
  );
};

export default ProofOfIdentity_doc_upload;
