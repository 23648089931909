import React, { createContext, useContext, useEffect, useState } from "react";
import {
  useLocation,
  Navigate,
  Outlet,
  useSearchParams,
} from "react-router-dom";
import {
  urlGeneratedAts,
  trnId,
  applicationType,
  newApplication,
} from "./store/registration/logindSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetApplicantStatusQuery,
  useGetDocumentSectionwiseQuery,
  useGetQuestionsSectionwiseQuery,
  useGetSectionStatusQuery,
} from "./services/identificationApi";
import Loader from "react-loader-spinner";
import { setViewOnlyFlag } from "./store/registration/fromSubmitSlice";
import { logout_url } from "./config";
import { BASE_URL_API } from "./config";
import { useGetMasterRegistrationDataQuery } from "./services/registrationApi";
import { JSONTree } from "react-json-tree";

const getUrlAndTransId = () => {
  const splitPath = window.location.pathname.split("/");
  splitPath.shift();
  if (splitPath[0] === "identification") {
    splitPath.shift();
  }
  let trn_id = null;
  let fullPath = null;
  if (
    (splitPath[0] === "Office" && splitPath?.length === 5) ||
    (splitPath[0] === "Unit" && splitPath?.length === 7) ||
    (splitPath[0] === "Floor" && splitPath?.length === 6) ||
    (splitPath[0] === "Property" && splitPath?.length === 5) ||
    (splitPath[0] === "Agent" && splitPath?.length === 5)
  ) {
    localStorage.setItem('applicationType', splitPath[0]);
    fullPath = splitPath.slice(0, splitPath?.length - 1).join("/");
    trn_id = splitPath[splitPath?.length - 1];
  } else {
    fullPath = splitPath.slice(0, splitPath?.length).join("/");
    trn_id = 0;
  }
  console.log("Check oject route", {
    url: fullPath,
    trn_id: trn_id,
    applicationType: splitPath[0],
  });
  sessionStorage.setItem('trn_id', trn_id);
  return {
    url: fullPath,
    trn_id: trn_id,
    applicationType: splitPath[0],
  };
};

// Create the context
const DocumentContext = createContext();

// Create a custom hook to use the context
export const useDocumentContext = () => {
  return useContext(DocumentContext);
};


const PrivateRoute = () => {
  const [documentData, setDocumentData] = useState(null);
  const [sectionData, setSectionData] = useState(null);
  const [identificationData, setIdentificationData] = useState(null);

  const token = useSelector((state) => state.persistedReducer.loginSlice.token);
  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  const url = useSelector(
    (state) => state.persistedReducer.loginSlice.urlGeneratedAts
  );

  let { data, isLoading, isError, isFetching } =
    useGetApplicantStatusQuery(trn_id);
  const { data: registrationPage, isLoading: LoadingRegistration, isSuccess } = useGetMasterRegistrationDataQuery({
    trn_id,
    url,
  });
  const { data: documentWise, isLoading: documentWiseLoading } = useGetDocumentSectionwiseQuery({
    profileID: registrationPage?.records?.ApplicationProfileID,
    subscriberId: registrationPage?.records?.subscriber_id
  }, {
    skip: !registrationPage?.records?.ApplicationProfileID && !registrationPage?.records?.subscriber_id, // Skip the query if either parameter is missing

  })

  const { data: sectionWise, isLoading: sectionWiseLoading } = useGetQuestionsSectionwiseQuery({
    profileID: registrationPage?.records?.ApplicationProfileID,
    subscriberId: registrationPage?.records?.subscriber_id
  }
    , {
      skip: !registrationPage?.records?.ApplicationProfileID && !registrationPage?.records?.subscriber_id, // Skip the query if either parameter is missing
    })

  const { data: identificationWise, isLoading: identificationLoading } = useGetQuestionsSectionwiseQuery({
    profileID: registrationPage?.records?.ApplicationProfileID,
    subscriberId: registrationPage?.records?.subscriber_id,
    profileTab: 'Documents Upload'
  }
    , {
      skip: !registrationPage?.records?.ApplicationProfileID && !registrationPage?.records?.subscriber_id, // Skip the query if either parameter is missing
    })

  useEffect(() => {
    if (documentWise) setDocumentData(documentWise);
    if (sectionWise) setSectionData(sectionWise);
    if (identificationWise) setIdentificationData(identificationWise);
  }, [documentWise, sectionWise, identificationWise]);

  if (isLoading && identificationLoading && sectionWiseLoading && documentWiseLoading)
    return (
      <Loader
        className="center-screen"
        type="Circles"
        color="#00BFFF"
        height={100}
        width={100}
        radius={200}
        timeout={3000} //3 secs
      />
    );

  // if (isError) return window.location.replace(logout_url);
  if (!token) {
    return window.location.replace(logout_url);
  }
  return token ? (
    (data?.records?.application_status == "Completed" || data?.records?.application_status == "Submitted") ? (
      <Navigate to="/success" />
    ) : (
      <DocumentContext.Provider value={{ documentData, sectionData, identificationData, documentWiseLoading, sectionWiseLoading, identificationLoading }}>
        <Outlet />
      </DocumentContext.Provider>
    )
  ) : (
    window.location.replace(logout_url)
    //  window.location.replace(logout_url)
  );
};

const ContextWrappingForSuccess = () => {
  const [documentData, setDocumentData] = useState(null);
  const [sectionData, setSectionData] = useState(null);
  const [identificationData, setIdentificationData] = useState(null);

  const token = useSelector((state) => state.persistedReducer.loginSlice.token);
  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  const url = useSelector(
    (state) => state.persistedReducer.loginSlice.urlGeneratedAts
  );

  let { data, isLoading, isError, isFetching } =
    useGetApplicantStatusQuery(trn_id);
  const { data: registrationPage, isLoading: LoadingRegistration, isSuccess } = useGetMasterRegistrationDataQuery({
    trn_id,
    url,
  });
  const { data: documentWise, isLoading: documentWiseLoading } = useGetDocumentSectionwiseQuery({
    profileID: registrationPage?.records?.ApplicationProfileID,
    subscriberId: registrationPage?.records?.subscriber_id
  }, {
    skip: !registrationPage?.records?.ApplicationProfileID && !registrationPage?.records?.subscriber_id, // Skip the query if either parameter is missing

  })

  const { data: sectionWise, isLoading: sectionWiseLoading } = useGetQuestionsSectionwiseQuery({
    profileID: registrationPage?.records?.ApplicationProfileID,
    subscriberId: registrationPage?.records?.subscriber_id
  }
    , {
      skip: !registrationPage?.records?.ApplicationProfileID && !registrationPage?.records?.subscriber_id, // Skip the query if either parameter is missing
    })

  const { data: identificationWise, isLoading: identificationLoading } = useGetQuestionsSectionwiseQuery({
    profileID: registrationPage?.records?.ApplicationProfileID,
    subscriberId: registrationPage?.records?.subscriber_id,
    profileTab: 'Documents Upload'
  }
    , {
      skip: !registrationPage?.records?.ApplicationProfileID && !registrationPage?.records?.subscriber_id, // Skip the query if either parameter is missing
    })

  useEffect(() => {
    if (documentWise) setDocumentData(documentWise);
    if (sectionWise) setSectionData(sectionWise);
    if (identificationWise) setIdentificationData(identificationWise);
  }, [documentWise, sectionWise, identificationWise]);

  if (isLoading && identificationLoading)
    return (
      <Loader
        className="center-screen"
        type="Circles"
        color="#00BFFF"
        height={100}
        width={100}
        radius={200}
        timeout={3000} //3 secs
      />
    );

  // if (isError) return window.location.replace(logout_url);
  if (!token) {
    return window.location.replace(logout_url);
  }
  return token ? (
    (data?.records?.application_status == "Completed" || data?.records?.application_status == "Submitted") ? (
      <DocumentContext.Provider value={{ documentData, sectionData, identificationData, documentWiseLoading, sectionWiseLoading, identificationLoading }}>
        <Outlet />
      </DocumentContext.Provider>
    ) : (
      <DocumentContext.Provider value={{ documentData, sectionData, identificationData, documentWiseLoading, sectionWiseLoading, identificationLoading }}>
        <Outlet />
      </DocumentContext.Provider>
    )
  ) : (
    window.location.replace(logout_url)
  );
};

const PrivateRouteViewOnly = () => {
  const [documentData, setDocumentData] = useState(null);
  const [sectionData, setSectionData] = useState(null);
  const [identificationData, setIdentificationData] = useState(null);
  const url = useSelector(
    (state) => state.persistedReducer.loginSlice.urlGeneratedAts
  );
  const token = useSelector((state) => state.persistedReducer.loginSlice.token);
  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  let { data, isLoading, isError, isFetching } =
    useGetApplicantStatusQuery(trn_id);

  // const auth = true; // determine if authorized, from context or however you're doing it
  let location = useLocation();
  const dispatch = useDispatch();
  dispatch(setViewOnlyFlag(true));
  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
 const { data: registrationPage, isLoading: LoadingRegistration, isSuccess } = useGetMasterRegistrationDataQuery({
    trn_id,
    url,
  });
  const { data: documentWise, isLoading: documentWiseLoading } = useGetDocumentSectionwiseQuery({
    profileID: registrationPage?.records?.ApplicationProfileID,
    subscriberId: registrationPage?.records?.subscriber_id
  }, {
    skip: !registrationPage?.records?.ApplicationProfileID && !registrationPage?.records?.subscriber_id, // Skip the query if either parameter is missing

  })

  const { data: sectionWise, isLoading: sectionWiseLoading } = useGetQuestionsSectionwiseQuery({
    profileID: registrationPage?.records?.ApplicationProfileID,
    subscriberId: registrationPage?.records?.subscriber_id
  }
    , {
      skip: !registrationPage?.records?.ApplicationProfileID && !registrationPage?.records?.subscriber_id, // Skip the query if either parameter is missing
    })

  const { data: identificationWise, isLoading: identificationLoading } = useGetQuestionsSectionwiseQuery({
    profileID: registrationPage?.records?.ApplicationProfileID,
    subscriberId: registrationPage?.records?.subscriber_id,
    profileTab: 'Documents Upload'
  }
    , {
      skip: !registrationPage?.records?.ApplicationProfileID && !registrationPage?.records?.subscriber_id, // Skip the query if either parameter is missing
    })

  useEffect(() => {
    if (documentWise) setDocumentData(documentWise);
    if (sectionWise) setSectionData(sectionWise);
    if (identificationWise) setIdentificationData(identificationWise);
  }, [documentWise, sectionWise, identificationWise]);

  if (isLoading && identificationLoading && sectionWiseLoading && documentWiseLoading)
    return (
      <Loader
        className="center-screen"
        type="Circles"
        color="#00BFFF"
        height={100}
        width={100}
        radius={200}
        timeout={3000} //3 secs
      />
    );
  // if (token)
  //   <Outlet />
  // else
  // return  window.location.replace(logout_url);
  return token ? (
    <DocumentContext.Provider value={{ documentData, sectionData, identificationData, documentWiseLoading, sectionWiseLoading, identificationLoading }}>
      <Outlet />
    </DocumentContext.Provider>
  ) : (
    window.location.replace(logout_url)
  );
};

const PrivateRouteInstructions = () => {
  //debugger;
  const token = useSelector((state) => state.persistedReducer.loginSlice.token);

  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  let { data, isLoading, isError, isFetching } =
    useGetSectionStatusQuery(trn_id);
  let location = useLocation();

  console.log("Check Application status in instruction route:Line 121", data);
  if (isLoading)
    return (
      <Loader
        className="center-screen"
        type="Circles"
        color="#00BFFF"
        height={100}
        width={100}
        radius={200}
        timeout={3000} //3 secs
      />
    );
  if (!token) {
    return window.location.replace(logout_url);
  }
  // return <>{JSON.stringify(data.accept_terms_and_condition_status === false)}{JSON.stringify(data.accept_terms_and_condition_status === false)}</>
  //  return <p>{token} sfsfss</p>
  return token ? (
    data?.accept_terms_and_condition_status === false ? (
      <Outlet />
    ) : (
      <Navigate to="/identification" state={{ from: location }} />
    )
  ) : (
    window.location.replace(logout_url)
  );
};

const RouteGuardLoginRegistration = () => {
  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  // if (!trn_id) return <Navigate to="/unauthorizedAccess" />;
  return <Outlet />;
};

const RouteCheckGuard = () => {
  //debugger;
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  dispatch(urlGeneratedAts(getUrlAndTransId().url));
  dispatch(trnId(getUrlAndTransId().trn_id));
  dispatch(applicationType(getUrlAndTransId().applicationType));
  dispatch(
    newApplication(searchParams.get("newapplication") == "true" ? true : false)
  );
  const registrationStatus = true;

  //if (!getUrlAndTransId().trn_id) return <Navigate to="/unauthorizedAccess" />;
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const auth = urlParams.get('t');
    setToken(auth);
    const myHeaders = new Headers();
    myHeaders.append(
      "auth",
      auth
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (!auth) {
      return setResult(true);
    }

    fetch(BASE_URL_API + "applicant/validate-invitation", requestOptions)
      .then((response) => response.json())
      .then((result) => setResult(true))
      .catch((error) => {
        alert("Invited link has been expired. Please contact your admin. ");
        setResult(false)
      });
  }, []);

  if (result === null) {
    return <div></div>;
  }

  if (result) {
    return <Navigate to="/registration" replace={true} />;
  } else {
    window.location.replace(logout_url);
    return null; // Ensure the component does not render anything after the redirect
  }

};

export {
  RouteCheckGuard,
  RouteGuardLoginRegistration,
  PrivateRouteViewOnly,
  PrivateRouteInstructions,
  ContextWrappingForSuccess
};
export default PrivateRoute;
