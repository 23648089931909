import React, { useEffect, useRef } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormProvider,
  useForm,
} from "react-hook-form";
import OtherIncome from "./other-income/other-income";
import OtherDocuments from "./other-documents/other-documents";
import {
  useGetIncomeBankingQuery,
  useGetSectionStatusQuery,
  useSavePayslipMutation,
} from "../../../services/identificationApi";
import BankingInformation from "./banking-information/banking-information";
import EmployementIncome from "./employement-income/employement-income";
import { useDispatch, useSelector } from "react-redux";
import { DevTool } from "@hookform/devtools";
import { ToastContainer, toast } from "react-toastify";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { AiOutlineCloseCircle } from "react-icons/ai";
import InputCurrencyField from "../../registration/Inputs/InputCurrencyField";
import NumberFormat from "react-number-format";
import Slide from "@mui/material/Slide";
import { useLocation, useNavigate } from "react-router-dom";
import { formSubmitStatusRest } from "../../../store/registration/fromSubmitSlice";
import {
  Accordion,
  AccordionSummary,
  Snackbar,
  Typography,
} from "@mui/material";
import { tabsStateAction } from "../../../store/identification/tabStateSlice";
import { BsCheck2Circle } from "react-icons/bs";
import MuiAlert from "@mui/material/Alert";
import { AiOutlinePlus } from "react-icons/ai";
import CloseIcon from "@mui/icons-material/Close";
import SupportSection from "../../common/SupportSection/SupportSection";
import { JSONTree } from "react-json-tree";
import { useDocumentContext } from "../../../PrivateRoute";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});




function IncomeBankingOtherForm(props) {
  const navigate = useNavigate();
  const [doSave, resultSave] = useSavePayslipMutation();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [indexVerifyGross, setIndexVerifyGross] = React.useState(0);
  const [clickHere, setClickHere] = React.useState(true);

  const [open1, setOpen1] = React.useState(false);
  const refSubmitButtom = useRef(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };
  const viewOnlyFlag = useSelector((state) => state.formSubmit.viewOnlyFlag);
  const { documentData:documentWiseRules, sectionData:paystubWiseRules, identificationData:identificationWiseRules } = useDocumentContext();

  const commonEmploymentFields = {
    employer_name: yup
      .string()
      .nullable() // Allow nullable in case it's not required
      .when([], {
        is: () => paystubWiseRules?.EmployerName === 'Required',
        then: yup
          .string()
          .typeError("Required")
          .required("Required")
          .matches(/^$|^\S+.*/g, "Enter a valid value"), // Validation for valid value
        otherwise: yup.string().nullable(), // If not required, allow it to be null
      }),
    manager_name: yup
      .string()
      .nullable() // Allow nullable in case it's not required
      .when([], {
        is: () => paystubWiseRules?.SupervisorName === 'Required',
        then: yup
          .string()
          .typeError("Required")
          .required("Required"),
        otherwise: yup.string().nullable(), // If not required, allow it to be null
      }),
    employer_state: yup
      .object()
      .nullable() // Allow nullable in case it's not required
      .when([], {
        is: () => paystubWiseRules?.EmployerState === 'Required',
        then: yup
          .object()
          .typeError("Required")
          .required("Required"),
        otherwise: yup.object().nullable(), // If not required, allow it to be null
      }),
    employer_city: yup
      .string()
      .nullable() // Allow nullable in case it's not required
      .when([], {
        is: () => paystubWiseRules?.EmployerCity === 'Required',
        then: yup
          .string()
          .typeError("Required")
          .required("Required"),
        otherwise: yup.string().nullable(), // If not required, allow it to be null
      }),
    employer_address: yup
      .string()
      .nullable() // Allow nullable in case it's not required
      .when([], {
        is: () => paystubWiseRules?.EmployerAddress === 'Required',
        then: yup
          .string()
          .trim()
          .typeError("Required")
          .required("Required"),
        otherwise: yup.string().nullable(), // If not required, allow it to be null
      }),
    position: yup
      .string()
      .nullable() // Allow nullable in case it's not required
      .when([], {
        is: () => paystubWiseRules?.Occupation === 'Required',
        then: yup
          .string()
          .typeError("Required")
          .required("Required"),
        otherwise: yup.string().nullable(), // If not required, allow it to be null
      }),
    employment_start_date: yup
      .string()
      .nullable() // Allow nullable in case it's not required
      .when([], {
        is: () => paystubWiseRules?.EmployerState === 'Required',
        then: yup
          .string()
          .typeError("Required")
          .required("Required"),
        otherwise: yup.string().nullable(), // If not required, allow it to be null
      }),
    availableToUpload: yup
      .string()
      .nullable()
      .when([], {
        is: () => documentWiseRules?.Paystub === 'Required',
        then: yup
          .string()
          .typeError("Required")
          .required("Required"),
        otherwise: yup.string().nullable(), // If not required, allow it to be null
      }),
  };
  const bankCommonFields = {
    bank_name: yup
      .string()
      .nullable()
      .when([], {
        is: () =>  true ,//paystubWiseRules?.BankName === 'Required',
        then: yup
          .string()
          .typeError("Required")
          .required("Required"),
        otherwise: yup.string().nullable(), // If not required, allow it to be null
      }),
    account_type: yup
      .object()
      .nullable()
      .when([], {
        is: () => true, //paystubWiseRules?.BankAccountType === 'Required',
        then: yup
          .object()
          .typeError("Required")
          .required("Required"),
        otherwise: yup.object().nullable(), // If not required, allow it to be null
      }),
    current_balance: yup
      .number()
      .nullable()
      .when([], {
        is: () => paystubWiseRules?.MonthlyAmount === 'Required',
        then: yup
          .number()
          .typeError("The value must be a number")
          .required("Required"),
        otherwise: yup.number().nullable().notRequired(), // If not required, allow it to be null
      }),
    availableToUpload: yup
      .string()
      .nullable()
      .when([], {
        is: () => documentWiseRules?.BankStatements === 'Required',
        then: yup
          .string()
          .typeError("Required")
          .required("Required"),
        otherwise: yup.string().nullable(), // If not required, allow it to be null
      }),
  };


  yup.addMethod(yup.array, "unique", function (message) {
    return this.test("unique", message, function (data) {
      const list = data.map((item) => {
        return { employer_name: item?.employer_name, position: item?.position };
      });
      let isUnique = true;
      let row = list?.length;
      for (let i = 0; i < row; i++) {
        for (let j = i + 1; j < row; j++) {
          if (JSON.stringify(list[i]) === JSON.stringify(list[j])) {
            isUnique = false;
          }
        }
      }
      if (isUnique) {
        return true;
      }
      return false;
    });
  });

  yup.addMethod(yup.array, "uniqueBank", function (message) {
    return this.test("unique", message, function (data) {
      const list = data.map((item) => {
        return { account_type: item?.account_type, bank_name: item?.bank_name };
      });
      let isUnique = true;
      let row = list?.length;
      for (let i = 0; i < row; i++) {
        for (let j = i + 1; j < row; j++) {
          if (JSON.stringify(list[i]) === JSON.stringify(list[j])) {
            isUnique = false;
          }
        }
      }
      if (isUnique) {
        return true;
      }
      return false;
    });
  });

  yup.addMethod(yup.array, "uniqueOtherIncome", function (message) {
    return this.test("unique", message, function (data) {
      const list = data.map((item) => {
        return { other_income_type: item?.other_income_type };
      });
      let isUnique = true;
      let row = list?.length;
      for (let i = 0; i < row; i++) {
        for (let j = i + 1; j < row; j++) {
          if (JSON.stringify(list[i]) === JSON.stringify(list[j])) {
            isUnique = false;
          }
        }
      }
      if (isUnique) {
        return true;
      }
      return false;
    });
  });

  const schema = yup.object().shape({
    employment_details: yup.object().shape({
      response: yup
        .string()
        .typeError("Response Required")
        .required("Response Required"),
      no_doc_reason: yup.string().nullable(),
      other_reason: yup.string().when("no_doc_reason", {
        is: (value) => {
          return value === "Other";
        },
        then: yup.string().typeError("Required").required("Required")
          .matches(/^$|^\S+.*/g, "Enter a valid value"),
        otherwise: yup.string().nullable(),
      }),
      employer: yup.array().when("response", {
        is: (value) => {
          return value === "Yes";
        },
        then: yup
          .array()
          .of(
            yup.object().shape({
              ...commonEmploymentFields,
              // manager_name: yup
              //   .string()
              //   .typeError("Required")
              //   .required("Required").matches(/^$|^\S+.*/g, "Enter a valid value"),
              phone_no: yup
                .string()
                .typeError("Required")
                .required("Required")

                .min(11, "The value is not a valid Phone Number"),
              employer_zipcode: yup
                .string()
                .typeError("Required")
                .matches(
                  /^(\d{4}[0-9]|\d{8}[0-9]|\d{4}[0-9]-\d{3}[0-9]|-----\d{3}[0-9])$/g,
                  "Please enter valid zip code"
                )
                .required("Required"),
              // employer_state: yup
              //   .object()
              //   .typeError("Required")
              //   .required("Required"),
              // employer_name: yup
              //   .string()
              //   .typeError("Required")
              //   .required("Required").matches(/^$|^\S+.*/g, "Enter a valid value"),
              // employer_city: yup
              //   .string()
              //   .typeError("Required")
              //   .required("Required").matches(/^$|^\S+.*/g, "Enter a valid value"),
              // employer_address: yup
              //   .string().trim()
              //   .typeError("Required")
              //   .required("Required").matches(/^$|^\S+.*/g, "Enter a valid value"),
              // availableToUpload: yup
              //   .string()
              //   .typeError("Required")
              //   .required("Required"),
              files: yup.array().when("availableToUpload", {
                is: (value) => {
                  return value == "Yes";
                },
                then: yup.array().min(1, "At Least 1 Document Is Required"),
              }),
              no_doc_reason: yup.string().when("availableToUpload", {
                is: (value) => {
                  return value == "No";
                },
                then: yup.string().nullable(),
              }),
              other_reason: yup
                .string()
                .nullable()
                .when("no_doc_reason", {
                  is: (value) => {
                    return value == "Other";
                  },
                  then: yup
                    .string()
                    .nullable()
                    .typeError("Required")
                    .required("Required")
                    .matches(/^$|^\S+.*/g, "Enter a valid value"),
                }),
              avrgross_pay: yup
                .number()
                .typeError("The value must be a number")
                .required("Required")
                .min(1, "The value must be greater than or equal to 1"),
              // position: yup.string().typeError("Required").required("Required").matches(/^$|^\S+.*/g, "Enter a valid value"),
              pay_frequency: yup
                .object()
                .typeError("Required")
                .required("Required"),
              employer_status: yup
                .object()
                .typeError("Required")
                .required("Required"),
              // employment_start_date: yup
              //   .string()
              //   .typeError("Required")
              //   .required("Required"),
              employment_end_date: yup.string().when("iscurrent_employer", {
                is: (value) => {
                  return value == false;
                },
                then: yup.string().when("isfuture_employer", {
                  is: (value) => {
                    console.log("Value Type", typeof value);
                    return value == false;
                  },
                  then: yup
                    .string()
                    .nullable()
                    .transform((curr, orig) => (orig === "" ? null : curr))
                    .typeError("Required")
                    .required("Required")
                    .test(
                      "end_date_compare",
                      "the date must be greater than the employment start date",
                      function (value, context) {
                        console.log("This Parent", this.parent);
                        const { employment_start_date } = this.parent;
                        const dateConvertMoveIn = new Date(
                          employment_start_date
                        );
                        const dateConvertMoveOut = new Date(value);
                        return (
                          dateConvertMoveOut.getTime() >
                          dateConvertMoveIn.getTime()
                        );
                      }
                    ),
                  otherwise: yup
                    .string()
                    .nullable()
                    .transform((curr, orig) => (orig === "" ? null : curr)),
                }),
                otherwise: yup
                  .string()
                  .nullable()
                  .transform((curr, orig) => (orig === "" ? null : curr)),
              }),
            })
          )
          .min(1, "must have atleast 1 records")
          .unique(
            "You cannot add duplicate employer with same name and position "
          ),
      }),
    }),
    other_income_details: yup.object().shape({
      response: yup
        .string()
        .typeError("Response Required")
        .required("Response Required"),

      otherincome: yup.array().when("response", {
        is: (value) => {
          return value === "Yes";
        },
        then: yup
          .array()
          .of(
            yup.object().shape({
              other_income_type: yup
                .object()
                .typeError("Required")
                .required("Required"),
              other_source: yup.string().when('other_income_type', {
                is: (value) => value?.value == 'other',
                then: yup.string()
                  .typeError("Required")
                  .required("Required").matches(/^$|^\S+.*/g, "Enter a valid value"),
                otherwise: yup.string().nullable()
              }),
              voucher_type: yup.string().when('other_income_type', {
                is: (value) => value?.value == 'Housing Assistance',
                then: yup.string()
                  .typeError("Required")
                  .required("Required"),
                otherwise: yup.string().nullable()
              }),
              pension_source: yup.string().when('other_income_type', {
                is: (value) => value?.value == 'Pension',
                then: yup.string()
                  .typeError("Required")
                  .required("Required").matches(/^$|^\S+.*/g, "Enter a valid value"),
                otherwise: yup.string().nullable()
              }),
              rental_assistance_type: yup.object().when('other_income_type', {
                is: (value) => {
                  return value?.value == 'Rental Assistance';
                },
                then: yup.object().shape({
                  label: yup.string().typeError("Required")
                    .required("Required"),
                  value: yup.string().typeError("Required")
                    .required("Required")
                })
                  .typeError("Required")
                  .required("Required"),

                otherwise: yup.object().nullable()
              }),
              other_rental_assistance: yup.string().when(['other_income_type', 'rental_assistance_type'], {
                is: (otherIncome, rentalAssistance) => otherIncome?.value == 'Rental Assistance' && rentalAssistance?.value == 'Other',
                then: yup.string()
                  .typeError("Required")
                  .required("Required").matches(/^$|^\S+.*/g, "Enter a valid value"),
                otherwise: yup.string().nullable()
              }),
              monthly_income: yup
                .number()
                .typeError("The value must be a number")
                .required("Required")
                .min(1, "The value must be greater than 0"),
              availableToUpload: yup
                .string()
                .nullable()
                .when([], {
                  is: () => documentWiseRules?.OtherIncome === 'Required',
                  then: yup
                    .string()
                    .typeError("Required")
                    .required("Required"),
                  otherwise: yup.string().nullable(), // If not required, allow it to be null
                }),
              files: yup.array().when("availableToUpload", {
                is: (value) => {
                  return value == "Yes";
                },
                then: yup.array().min(1, "At Least 1 Document Is Required"),
              }),
              no_doc_reason: yup.string().when("availableToUpload", {
                is: (value) => {
                  return value == "No";
                },
                then: yup.string().nullable(),
                // .typeError("required")
                // .required("required"),
              }),
              other_reason: yup
                .string()
                .nullable()
                .when("no_doc_reason", {
                  is: (value) => {
                    return value == "other";
                  },
                  then: yup
                    .string()
                    .nullable()
                    .typeError("Required")
                    .required("Required")
                    .matches(/^$|^\S+.*/g, "Enter a valid value"),
                }),
            })
          )
          .min(1, "must have atleast 1 records")
          .uniqueOtherIncome("You cannot add duplicate Other income "),
      }),
    }),
    banking_details: yup.object().shape({
      response: yup
        .string()
        .typeError("Response Required")
        .required("Response Required"),
      banking: yup.array().when("response", {
        is: (value) => {
          return value === "Yes";
        },
        then: yup
          .array()
          .of(
            yup.object().shape({
              ...bankCommonFields,
              // bank_name: yup.string().required("Required"),
              // account_type: yup
              //   .object()
              //   .typeError("Required")
              //   .required("Required"),
              // current_balance: yup.number().nullable().notRequired(),
              // availableToUpload: yup
              //   .string()
              //   .typeError("Required")
              //   .required("Required"),
              files: yup.array().when("availableToUpload", {
                is: (value) => {
                  return value == "Yes";
                },
                then: yup.array().min(1, "At Least 1 Document Is Required"),
              }),
              no_doc_reason: yup.string().when("availableToUpload", {
                is: (value) => {
                  return value == "No";
                },
                then: yup.string().nullable(),
                otherwise: yup.string().nullable(),
              }),
              other_reason: yup
                .string()
                .nullable()
                .when("no_doc_reason", {
                  is: (value) => {
                    return value == "other";
                  },
                  then: yup
                    .string()
                    .nullable()
                    .typeError("Required")
                    .required("Required")
                    .matches(/^$|^\S+.*/g, "Enter a valid value"),
                }),
              // .typeError("The value must be a number"),
              // .required("required")
              // .min(1, "The value must be greater than or equal to 1"),
            })
          )
          .min(1, "must have atleast 1 records")
          .uniqueBank("You cannot add duplicate bank"),
      }),
    }),
  });

  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  const { data } = useGetIncomeBankingQuery(trn_id);
  const formData = data;
  const methods = useForm({
    defaultValues: formData,
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const handleSave = () => {
    if (methods.formState.isValid) {
      methods.setValue(
        `employment_details.employer.${indexVerifyGross}.gross_pay_verify`,
        true,
        true
      );
    }

    let _monthlyIncome =
      methods.getValues(
        `employment_details.employer.${indexVerifyGross}.new_avrgross_pay`
      ) || 0;
    const pay_frequency = methods.getValues(
      `employment_details.employer[${indexVerifyGross}].pay_frequency`
    ).value;
    if (pay_frequency == "Weekly") {
      _monthlyIncome = (_monthlyIncome / 52) * 12;
    } else if (pay_frequency == "Bi-Weekly") {
      _monthlyIncome = (_monthlyIncome / 26) * 12;
    } else if (pay_frequency == "Monthly") {
      //_monthlyIncome = _monthlyIncome;
    } else if (pay_frequency == "Bi-Monthly") {
      _monthlyIncome = (_monthlyIncome / 24) * 12;
    } else if (pay_frequency == "One-Time") {
      _monthlyIncome = _monthlyIncome * 12;
    } else {
      _monthlyIncome = _monthlyIncome * 12;
    }
    methods.setValue(
      `employment_details.employer.${indexVerifyGross}.avrgross_pay`,
      parseFloat(_monthlyIncome).toFixed(2),
      true
    );

    methods.setValue(
      `employment_details.employer.${indexVerifyGross}.new_avrgross_pay`,
      0,
      true
    );
    setOpen1(false);
    setOpen(false);
    setTimeout(() => {
      refSubmitButtom?.current?.click();
    }, 100);
  };
  const formSectionSubmit = useSelector(
    (state) => state.formSubmit.fromSectionSubmit
  );
  const submitBtnRef = useRef(null);

  useEffect(() => {
    (async () => {
      if (formSectionSubmit) {
        if (!(await handleSubmitValidation("Income"))) return;
        if (!(await handleSubmitValidation("OtherIncome"))) return;
        if (!(await handleSubmitValidation("Bank"))) return;
        submitBtnRef?.current?.click();
      }
    })();
  }, [formSectionSubmit]);

  const otherIncomeFormBinding = () => {
    const otherIncomeDefaultValue = formData.other_income_details;
    if (
      otherIncomeDefaultValue.otherincome?.length <= 0 ||
      methods.getValues().other_income_details.otherincome?.length <= 0
    ) {
      const response = methods.getValues()?.other_income_details?.response;
      return { ...formData.other_income_details, response };
    }
    const otherIncome = methods
      .getValues()
      .other_income_details.otherincome.map((item, index) => {
        // check if already present employer source update
        const replaceItem = otherIncomeDefaultValue.otherincome.find(
          (arrItem) => arrItem.id == item.id
        );
        if (!replaceItem) return item;
        return {
          ...item,
          id: replaceItem.id,
          files: replaceItem.files,
          fileCount: replaceItem.fileCount,
        };
      });

    if (
      otherIncomeDefaultValue.otherincome?.length ===
      methods.getValues().other_income_details.otherincome?.length
    ) {
      return {
        ...methods.getValues().other_income_details,
        otherincome: otherIncome,
      };
    }

    const newOtherIncome = otherIncomeDefaultValue.otherincome.map(
      (arrItem, index) => {
        const replaceItem = otherIncome.find(
          (empItem) => arrItem.id == empItem.id
        );
        if (!replaceItem) return arrItem;
        return {
          ...arrItem,
          id: replaceItem.id,
          files: replaceItem.files,
          fileCount: replaceItem.fileCount,
          other_income_type: replaceItem.other_income_type,
          monthly_income: replaceItem.monthly_income,
          no_doc_reason: replaceItem.no_doc_reason,
          other_reason: replaceItem.other_reason,
          availableToUpload: replaceItem.availableToUpload
        };
      }
    );

    return {
      ...methods.getValues().other_income_details,
      otherincome: newOtherIncome,
    };
  };
  useEffect(() => {
    let payslipDefaultValue = formData.employment_details;
    if (
      payslipDefaultValue.employer?.length <= 0 ||
      methods.getValues().employment_details.employer?.length <= 0
    ) {
      const response = methods.getValues()?.employment_details?.response;
      const no_doc_reason =
        methods.getValues()?.employment_details?.no_doc_reason;
      const other_reason =
        methods.getValues()?.employment_details?.other_reason;
      if (response == "No") {
        payslipDefaultValue = {
          ...formData.employment_details,
          response,
          no_doc_reason,
          other_reason,
        };
      } else payslipDefaultValue = { ...formData.employment_details, response };
    } else {
      //const methods.getValues().employment_details = methods.getValues();
      /**
       * This is for file upload update form
       */
      const employer = methods
        .getValues()
        .employment_details.employer.map((item, index) => {
          // check if already present employer source update
          const replaceItem = payslipDefaultValue.employer.find(
            (arrItem) => arrItem.id == item.id
          );
          if (!replaceItem) return item;
          return {
            ...item,
            id: replaceItem.id,
            files: replaceItem.files,
            fileCount: replaceItem.fileCount,
            validation_message_status: replaceItem.validation_message_status,
            gross_pay_verify: false,
            monthly_avr_gross: replaceItem.monthly_avr_gross,
            // no_doc_reason: replaceItem.no_doc_reason,
          };
        });

      if (
        payslipDefaultValue.employer?.length ===
        methods.getValues().employment_details.employer?.length
      ) {
        payslipDefaultValue = {
          ...methods.getValues().employment_details,
          employer: employer,
        };
      } else {
        const newEmployer = payslipDefaultValue.employer.map(
          (arrItem, index) => {
            const replaceItem = employer.find(
              (empItem) => arrItem.id == empItem.id
            );
            if (!replaceItem) return arrItem;

            return {
              ...arrItem,
              employer_name: replaceItem.employer_name,
              position: replaceItem.position,
              phone_no: replaceItem.phone_no,
              employer_address: replaceItem.employer_address,
              employer_city: replaceItem.employer_city,
              employer_state: replaceItem.employer_state,
              employer_zipcode: replaceItem.employer_zipcode,
              employment_start_date: replaceItem.employment_start_date,
              employment_end_date: replaceItem.employment_end_date,
              iscurrent_employer: replaceItem.iscurrent_employer,
              isfuture_employer: replaceItem.isfuture_employer,
              manager_name: replaceItem.manager_name,
              no_doc_reason: replaceItem.no_doc_reason,
              total_income: replaceItem.total_income,
              total_annual_income: replaceItem.total_annual_income,
              avrgross_pay: replaceItem.avrgross_pay,
              pay_frequency: replaceItem.pay_frequency,
              gross_pay_verify: replaceItem.gross_pay_verify,
              other_reason: replaceItem.other_reason,
              availableToUpload: replaceItem.availableToUpload,
              employer_status: replaceItem.employer_status,
            };
          }
        );
        payslipDefaultValue = {
          ...payslipDefaultValue,
          employer: newEmployer,
        };
      }
    }

    ////////////////////////////////////////////

    ////////////////////////////////////////////

    //////////////////////////////////////////
    let bankDefaultValue = formData.banking_details;
    if (
      bankDefaultValue.banking?.length <= 0 ||
      methods.getValues().banking_details.banking?.length <= 0
    ) {
      const response = methods.getValues()?.banking_details?.response;
      bankDefaultValue = { ...formData.banking_details, response };
    } else {
      const bank = methods
        .getValues()
        .banking_details.banking.map((item, index) => {
          // check if already present employer source update
          const replaceItem = bankDefaultValue.banking.find(
            (arrItem) => arrItem.id == item.id
          );
          if (!replaceItem) return item;
          return {
            ...item,
            id: replaceItem.id,
            files: replaceItem.files,
            fileCount: replaceItem.fileCount,
          };
        });

      if (
        bankDefaultValue.banking?.length ===
        methods.getValues().banking_details.banking?.length
      ) {
        bankDefaultValue = {
          ...methods.getValues().banking_details, //**ISSUE IS HERE SET other_income_details INSTED OF banking_details */
          banking: bank,
        };
      } else {
        const newBank = bankDefaultValue.banking.map((arrItem, index) => {
          const replaceItem = bank.find((empItem) => arrItem.id == empItem.id);
          console.log("replaceItem:528", replaceItem);
          if (!replaceItem) return arrItem;
          return {
            ...arrItem,
            id: replaceItem.id,
            files: replaceItem.files,
            fileCount: replaceItem.fileCount,
            bank_name: replaceItem.bank_name,
            account_type: replaceItem.account_type,
            current_balance: replaceItem.current_balance,
            no_doc_reason: replaceItem.no_doc_reason,
            availableToUpload: replaceItem.availableToUpload
          };
        });
        bankDefaultValue = {
          ...methods.getValues().banking_details,
          banking: newBank,
        };
      }
    }
    // this for isolating methods
    const resetFormData = {
      ...formData,
      employment_details: {
        ...formData.employment_details,
        ...payslipDefaultValue,
      },
      other_income_details: {
        ...formData.other_income_details,
        ...otherIncomeFormBinding(),
      },
      banking_details: {
        ...formData.banking_details,
        ...bankDefaultValue,
      },
    };
    methods.reset(resetFormData);
  }, [formData, methods]);

  const onSubmit = async (formBody) => {
    const employer = formBody.employment_details.employer.map((data) => {
      return {
        ...data,
        pay_frequency: data.pay_frequency?.value,
        employer_status: data.employer_status?.label,
        no_doc_reason: data.files?.length > 0 ? null : data.no_doc_reason,
        other_reason: data?.availableToUpload !== "Yes" && data?.no_doc_reason == "Other" ? data?.other_reason : null,
      };
    });

    const otherIncome = formBody.other_income_details.otherincome.map(
      (data) => {
        return {
          ...data,
          other_income_type: data.other_income_type?.value,
          no_doc_reason: data.files?.length > 0 ? null : data?.no_doc_reason == "other" ? data?.other_reason : data.no_doc_reason,
          other_reason: data?.availableToUpload !== "Yes" && data?.no_doc_reason == "other" ? data?.other_reason : null,
        };
      }
    );

    const bank = formBody.banking_details.banking.map((data) => {
      return {
        ...data,
        bank_name: data.bank_name,
        account_type: data.account_type?.value,
        no_doc_reason: data.files?.length > 0 ? null : data?.no_doc_reason == "other" ? data?.other_reason : data.no_doc_reason,
        other_reason: data?.availableToUpload !== "Yes" && data?.no_doc_reason == "other" ? data?.other_reason : null,
      };
    });
    console.log("FormBody:592", formBody);

    let index = 0;
    for (let emp of employer) {
      // console.log(
      //   "🚀 ~ file: income-banking-other-form.js ~ line 428 ~ onSubmit ~ emp",
      //   emp
      // );
      if (emp?.files?.length)
        if (emp.monthly_avr_gross)
          if (
            !emp.gross_pay_verify &&
            emp.total_income != emp.monthly_avr_gross
          ) {
            setIndexVerifyGross(index);
            setOpen(true);
            return;
          }
      index++;
    }

    const postData = {
      ...formBody,
      employment_details: {
        ...formBody.employment_details,
        employer: employer,
        other_reason: formBody?.employment_details?.response !== "Yes" && formBody?.employment_details?.no_doc_reason == "Other" ? formBody?.employment_details?.other_reason : null
      },
      other_income_details: {
        ...formBody.other_income_details,
        otherincome: otherIncome,
      },
      banking_details: {
        ...formBody.banking_details,
        banking: bank,
      },
    };
    console.log("Check Post Data:790", postData);
    const response = await toast.promise(doSave(postData), {
      pending: "Saving",

      error: "Saving failed 🤯",
    });
    if (response) {
      dispatch(formSubmitStatusRest());
      dispatch(tabsStateAction.sideNavIncomeReducer(true));
      navigate("/identification/payment");
    } else {
      toast.error("Something went wrong");
    }
  };
  useEffect(() => {
    dispatch(tabsStateAction.sideNavIncomeReducer(false));
  }, []);

  const IncomeStatus = useSelector(
    (state) => state.identification.tabsStateSlice.IncomeStatus
  );

  const IncomeErr = useSelector(
    (state) => state.identification.tabsStateSlice.IncomeErr
  );

  const OtherIncomeStatus = useSelector(
    (state) => state.identification.tabsStateSlice.OtherIncomeStatus
  );
  const BankStatus = useSelector(
    (state) => state.identification.tabsStateSlice.BankStatus
  );

  const OtherDocStatus = useSelector(
    (state) => state.identification.tabsStateSlice.OtherDocStatus
  );



  const handleSubmitValidation = async (parms, submit = true) => {
    if (parms === "Income") {
      const localSchema = yup.object().shape({
        employment_details: yup.object().shape({
          response: yup
            .string()
            .typeError("Response Required")
            .required("Response Required"),
          other_reason: yup.string().when("no_doc_reason", {
            is: (value) => {
              return value === "Other";
            },
            then: yup.string().typeError("Required").required("Required"),
            otherwise: yup.string().nullable(),
          }),
          employer: yup.array().when("response", {
            is: (value) => {
              return value === "Yes";
            },
            then: yup
              .array()
              .of(
                yup.object().shape({
                  ...commonEmploymentFields,
                  // manager_name: yup
                  //   .string()
                  //   .typeError("Required")
                  //   .required("Required"),
                  phone_no: yup
                    .string()
                    .typeError("Required")
                    .required("Required")
                    .min(11, "The value is not a valid Phone Number"),
                  employer_zipcode: yup
                    .string()
                    .typeError("Required")
                    .matches(
                      /^(\d{4}[0-9]|\d{8}[0-9]|\d{4}[0-9]-\d{3}[0-9]|-----\d{3}[0-9])$/g,
                      "Please enter valid zip code"
                    )
                    .required("Required"),
                  // employer_state: yup
                  //   .object()
                  //   .typeError("Required")
                  //   .required("Required"),
                  // employer_name: yup
                  //   .string()
                  //   .typeError("required")
                  //   .required("required"),
                  // employer_city: yup
                  //   .string()
                  //   .typeError("Required")
                  //   .required("Required"),
                  // employer_address: yup
                  //   .string().trim()
                  //   .typeError("Required")
                  //   .required("Required"),
                  // availableToUpload: yup
                  //   .string()
                  //   .typeError("Required")
                  //   .required("Required"),
                  files: yup.array().when("availableToUpload", {
                    is: (value) => {
                      return value == "Yes";
                    },
                    then: yup.array().min(1, "At Least 1 Document Is Required"),
                  }),
                  avrgross_pay: yup
                    .number()
                    .typeError("The value must be a number")
                    .required("Required")
                    .min(1, "The value must be greater than or equal to 1"),
                  // position: yup
                  //   .string()
                  //   .typeError("Required")
                  //   .required("Required"),
                  pay_frequency: yup
                    .object()
                    .typeError("Required")
                    .required("Required"),
                  // employment_start_date: yup
                  //   .string()
                  //   .typeError("Required")
                  //   .required("Required"),
                  employment_end_date: yup.string().when("iscurrent_employer", {
                    is: (value) => {
                      return value == false;
                    },
                    then: yup.string().when("isfuture_employer", {
                      is: (value) => {
                        console.log("Value Type", typeof value);
                        return value == false;
                      },
                      then: yup
                        .string()
                        .nullable()
                        .transform((curr, orig) => (orig === "" ? null : curr))
                        .typeError("Required")
                        .required("Required")
                        .test(
                          "end_date_compare",
                          "the date must be greater than the employment start date",
                          function (value, context) {
                            console.log("This Parent", this.parent);
                            const { employment_start_date } = this.parent;
                            const dateConvertMoveIn = new Date(
                              employment_start_date
                            );
                            const dateConvertMoveOut = new Date(value);
                            return (
                              dateConvertMoveOut.getTime() >
                              dateConvertMoveIn.getTime()
                            );
                          }
                        ),
                      otherwise: yup
                        .string()
                        .nullable()
                        .transform((curr, orig) => (orig === "" ? null : curr)),
                    }),
                    otherwise: yup
                      .string()
                      .nullable()
                      .transform((curr, orig) => (orig === "" ? null : curr)),
                  }),
                })
              )
              .min(1, "must have atleast 1 records")
              .unique(
                "You cannot add duplicate employer with same name and position "
              ),
          }),
        }),
      });
      try {
        const result = await localSchema.isValid(methods.getValues());
        if (!result) {
          if (!submit) return false;
          submitBtnRef?.current?.click();
          dispatch(tabsStateAction.Income(false));
          dispatch(tabsStateAction.IncomeErr(true));
          return false;
        }
        return true;
      } catch (error) {
        console.error(
          "🚀 ~ file: identification-details.js ~ line 709 ~ handleChange ~ error",
          error
        );
      }
    } else if (parms === "OtherIncome") {
      const localSchema = yup.object().shape({
        other_income_details: yup.object().shape({
          response: yup
            .string()
            .typeError("Response Required")
            .required("Response Required"),
          otherincome: yup.array().when("response", {
            is: (value) => {
              return value === "Yes";
            },
            then: yup
              .array()
              .of(
                yup.object().shape({
                  other_income_type: yup
                    .object()
                    .typeError("Required")
                    .required("Required"),
                  other_source: yup.string().when('other_income_type', {
                    is: (value) => value?.value == 'Other',
                    then: yup.string()
                      .typeError("Required")
                      .required("Required").matches(/^$|^\S+.*/g, "Enter a valid value"),
                    otherwise: yup.string().nullable()
                  }),
                  voucher_type: yup.string().when('other_income_type', {
                    is: (value) => value?.value == 'Housing Assistance',
                    then: yup.string()
                      .typeError("Required")
                      .required("Required"),
                    otherwise: yup.string().nullable()
                  }),
                  pension_source: yup.string().when('other_income_type', {
                    is: (value) => value?.value == 'Pension',
                    then: yup.string()
                      .typeError("Required")
                      .required("Required").matches(/^$|^\S+.*/g, "Enter a valid value"),
                    otherwise: yup.string().nullable()
                  }),
                  rental_assistance_type: yup.object().when('other_income_type', {
                    is: (value) => {
                      return value?.value == 'Rental Assistance';
                    },
                    then: yup.object().shape({
                      label: yup.string().typeError("Required")
                        .required("Required"),
                      value: yup.string().typeError("required")
                        .required("Required")
                    })
                      .typeError("Required")
                      .required("Required"),
                    otherwise: yup.object().nullable()
                  }),
                  other_rental_assistance: yup.string().when(['other_income_type', 'rental_assistance_type'], {
                    is: (otherIncome, rentalAssistance) => otherIncome?.value == 'Rental Assistance' && rentalAssistance?.value == 'Other',
                    then: yup.string()
                      .typeError("Required")
                      .required("Required").matches(/^$|^\S+.*/g, "Enter a valid value"),
                    otherwise: yup.string().nullable()
                  }),

                  availableToUpload: yup
                    .string()
                    .nullable()
                    .when([], {
                      is: () => documentWiseRules?.OtherIncome === 'Required',
                      then: yup
                        .string()
                        .typeError("Required")
                        .required("Required"),
                      otherwise: yup.string().nullable(), // If not required, allow it to be null
                    }),
                  files: yup.array().when("availableToUpload", {
                    is: (value) => {
                      return value == "Yes";
                    },
                    then: yup.array().min(1, "At Least 1 Document Is Required"),
                  }),
                  monthly_income: yup
                    .number()
                    .typeError("The value must be a number")
                    .required("Required")
                    .min(1, "The value must be greater than 0"),
                  other_reason: yup
                    .string()
                    .nullable()
                    .when("no_doc_reason", {
                      is: (value) => {
                        return value == "other";
                      },
                      then: yup
                        .string()
                        .nullable()
                        .typeError("Required")
                        .required("Required"),
                    }),
                })
              )
              .min(1, "must have atleast 1 records")
              .uniqueOtherIncome("You cannot add duplicate Other income "),
          }),
        }),
      });
      try {
        const result = await localSchema.isValid(methods.getValues());
        if (!result) {
          if (!submit) return false;
          submitBtnRef?.current?.click();
          dispatch(tabsStateAction.OtherIncome(false));
          dispatch(tabsStateAction.IncomeErr(true));
          return false;
        }
        return true;
      } catch (error) {
        console.error(
          "🚀 ~ file: identification-details.js ~ line 709 ~ handleChange ~ error",
          error
        );
      }
    } else if (parms === "Bank") {
      const localSchema = yup.object().shape({
        banking_details: yup.object().shape({
          response: yup
            .string()
            .typeError("Response Required")
            .required("Response Required"),
          banking: yup.array().when("response", {
            is: (value) => {
              return value === "Yes";
            },
            then: yup
              .array()
              .of(
                yup.object().shape({
                  ...bankCommonFields,
                  // bank_name: yup.string().required("Required"),
                  // availableToUpload: yup
                  //   .string()
                  //   .typeError("Required")
                  //   .required("Required"),
                  files: yup.array().when("availableToUpload", {
                    is: (value) => {
                      return value == "Yes";
                    },
                    then: yup.array().min(1, "At Least 1 Document Is Required"),
                  }),
                  // account_type: yup
                  //   .object()
                  //   .typeError("Required")
                  //   .required("Required"),
                  // current_balance: yup.number().nullable().notRequired(),
                  other_reason: yup
                    .string()
                    .nullable()
                    .when("no_doc_reason", {
                      is: (value) => {
                        return value == "other";
                      },
                      then: yup
                        .string()
                        .nullable()
                        .typeError("Required")
                        .required("Required"),
                    }),
                })
              )
              .min(1, "must have atleast 1 records")
              .uniqueBank("You cannot add duplicate bank"),
          }),
        }),
      });
      try {
        const result = await localSchema.isValid(methods.getValues());
        if (!result) {
          if (!submit) return false;
          submitBtnRef?.current?.click();
          dispatch(tabsStateAction.Bank(false));
          dispatch(tabsStateAction.IncomeErr(true));
          return false;
        }
        return true;
      } catch (error) {
        console.error(
          "🚀 ~ file: identification-details.js ~ line 709 ~ handleChange ~ error",
          error
        );
      }
    }
  };
  const handleChange = async (parms) => {
    const incomeSchema = yup.object().shape({
      employment_details: yup.object().shape({
        response: yup
          .string()
          .typeError("Response Required")
          .required("Response Required"),
        other_reason: yup.string().when("no_doc_reason", {
          is: (value) => {
            return value === "Other";
          },
          then: yup.string().typeError("Required").required("Required"),
          otherwise: yup.string().nullable(),
        }),
        employer: yup.array().when("response", {
          is: (value) => {
            return value === "Yes";
          },
          then: yup
            .array()
            .of(
              yup.object().shape({
                ...commonEmploymentFields,
                phone_no: yup
                  .string()
                  .typeError("Required")
                  .required("Required")
                  .min(11, "The value is not a valid Phone Number"),
                employer_zipcode: yup
                  .string()
                  .typeError("Required")
                  // .matches(/^(\d{5})$/g, "Please enter valid zip code")
                  .matches(
                    /^(\d{4}[0-9]|\d{8}[0-9]|\d{4}[0-9]-\d{3}[0-9]|-----\d{3}[0-9])$/g,
                    "Please enter valid zip code"
                  )
                  .required("Required"),
                // availableToUpload: yup
                //   .string()
                //   .typeError("Required")
                //   .required("Required"),
                files: yup.array().when("availableToUpload", {
                  is: (value) => {
                    return value == "Yes";
                  },
                  then: yup.array().min(1, "At Least 1 Document Is Required"),
                }),
                avrgross_pay: yup
                  .number()
                  .typeError("The value must be a number")
                  .required("Required")
                  .min(1, "The value must be greater than or equal to 1"),
                // position: yup
                //   .string()
                //   .typeError("Required")
                //   .required("Required"),
                pay_frequency: yup
                  .object()
                  .typeError("Required")
                  .required("Required"),
                // employment_start_date: yup
                //   .string()
                //   .typeError("Required")
                //   .required("Required"),
                employment_end_date: yup.string().when("iscurrent_employer", {
                  is: (value) => {
                    return value == false;
                  },
                  then: yup.string().when("isfuture_employer", {
                    is: (value) => {
                      console.log("Value Type", typeof value);
                      return value == false;
                    },
                    then: yup
                      .string()
                      .nullable()
                      .transform((curr, orig) => (orig === "" ? null : curr))
                      .typeError("Required")
                      .required("Required")
                      .test(
                        "end_date_compare",
                        "the date must be greater than the employment start date",
                        function (value, context) {
                          console.log("This Parent", this.parent);
                          const { employment_start_date } = this.parent;
                          const dateConvertMoveIn = new Date(
                            employment_start_date
                          );
                          const dateConvertMoveOut = new Date(value);
                          return (
                            dateConvertMoveOut.getTime() >
                            dateConvertMoveIn.getTime()
                          );
                        }
                      ),
                    otherwise: yup
                      .string()
                      .nullable()
                      .transform((curr, orig) => (orig === "" ? null : curr)),
                  }),
                  otherwise: yup
                    .string()
                    .nullable()
                    .transform((curr, orig) => (orig === "" ? null : curr)),
                }),
                // employment_end_date: yup
                //   .string()
                //   .nullable()
                //   .test(
                //     "end_date_compare",
                //     "the date must be greater than the employment start date",
                //     function (value, context) {
                //       const { move_in_date } = this.parent;
                //       const dateConvertMoveIn = new Date(move_in_date);
                //       const dateConvertMoveOut = new Date(value);
                //       return (
                //         dateConvertMoveOut.getTime() >
                //         dateConvertMoveIn.getTime()
                //       );
                //     }
                //   ),
              })
            )
            .min(1, "must have atleast 1 records")
            .unique(
              "You cannot add duplicate employer with same name and position "
            ),
        }),
      }),
    });
    const validateIncome = await incomeSchema.isValid(methods.getValues());
    const otherIncomeSchema = yup.object().shape({
      other_income_details: yup.object().shape({
        response: yup
          .string()
          .typeError("Response Required")
          .required("Response Required"),
        otherincome: yup.array().when("response", {
          is: (value) => {
            return value === "Yes";
          },
          then: yup
            .array()
            .of(
              yup.object().shape({
                other_income_type: yup
                  .object()
                  .typeError("Required")
                  .required("Required"),
                other_source: yup.string().when('other_income_type', {
                  is: (value) => value?.value == 'Other',
                  then: yup.string()
                    .typeError("Required")
                    .required("Required").matches(/^$|^\S+.*/g, "Enter a valid value"),
                  otherwise: yup.string().nullable()
                }),
                voucher_type: yup.string().when('other_income_type', {
                  is: (value) => value?.value == 'Housing Assistance',
                  then: yup.string()
                    .typeError("Required")
                    .required("Required"),
                  otherwise: yup.string().nullable()
                }),
                pension_source: yup.string().when('other_income_type', {
                  is: (value) => value?.value == 'Pension',
                  then: yup.string()
                    .typeError("Required")
                    .required("Required").matches(/^$|^\S+.*/g, "Enter a valid value"),
                  otherwise: yup.string().nullable()
                }),
                rental_assistance_type: yup.object().when('other_income_type', {
                  is: (value) => {
                    return value?.value == 'Rental Assistance';
                  },
                  then: yup.object().shape({
                    label: yup.string().typeError("Required")
                      .required("Required"),
                    value: yup.string().typeError("Required")
                      .required("Required")
                  })
                    .typeError("Required")
                    .required("Required"),
                  otherwise: yup.object().nullable()
                }),

                other_rental_assistance: yup.string().when(['other_income_type', 'rental_assistance_type'], {
                  is: (otherIncome, rentalAssistance) => otherIncome?.value == 'Rental Assistance' && rentalAssistance?.value == 'Other',
                  then: yup.string()
                    .typeError("Required")
                    .required("Required").matches(/^$|^\S+.*/g, "Enter a valid value"),
                  otherwise: yup.string().nullable()
                }),

                availableToUpload: yup
                  .string()
                  .nullable()
                  .when([], {
                    is: () => documentWiseRules?.OtherIncome === 'Required',
                    then: yup
                      .string()
                      .typeError("Required")
                      .required("Required"),
                    otherwise: yup.string().nullable(), // If not required, allow it to be null
                  }),
                files: yup.array().when("availableToUpload", {
                  is: (value) => {
                    return value == "Yes";
                  },
                  then: yup.array().min(1, "At Least 1 Document Is Required"),
                }),
                monthly_income: yup
                  .number()
                  .typeError("The value must be a number")
                  .required("Required")
                  .min(1, "The value must be greater than 0"),
                other_reason: yup
                  .string()
                  .nullable()
                  .when("no_doc_reason", {
                    is: (value) => {
                      return value == "other";
                    },
                    then: yup
                      .string()
                      .nullable()
                      .typeError("Required")
                      .required("Required").matches(/^$|^\S+.*/g, "Enter a valid value"),
                  }),
              })
            )
            .min(1, "must have atleast 1 records")
            .uniqueOtherIncome("You cannot add duplicate Other income "),
        }),
      }),
    });
    const validateOtherIncome = await otherIncomeSchema.isValid(methods.getValues());
    const bankSchema = yup.object().shape({
      banking_details: yup.object().shape({
        response: yup
          .string()
          .typeError("Response Required")
          .required("Response Required"),
        banking: yup.array().when("response", {
          is: (value) => {
            return value === "Yes";
          },
          then: yup
            .array()
            .of(
              yup.object().shape({
                ...bankCommonFields,
                bank_name: yup.string().required("Required"),
                // account_type: yup
                //   .object()
                //   .typeError("Required")
                //   .required("Required"),
                // current_balance: yup.number().nullable().notRequired(),
                // availableToUpload: yup
                //   .string()
                //   .typeError("Required")
                //   .required("Required"),
                files: yup.array().when("availableToUpload", {
                  is: (value) => {
                    return value == "Yes";
                  },
                  then: yup.array().min(1, "At Least 1 Document Is Required"),
                }),
                other_reason: yup
                  .string()
                  .nullable()
                  .when("no_doc_reason", {
                    is: (value) => {
                      return value == "other";
                    },
                    then: yup
                      .string()
                      .nullable()
                      .typeError("Required")
                      .required("Required").matches(/^$|^\S+.*/g, "Enter a valid value"),
                  }),
              })
            )
            .min(1, "must have atleast 1 records")
            .uniqueBank("You cannot add duplicate bank"),
        }),
      }),
    });
    const validateBank = await bankSchema.isValid(methods.getValues());
    console.log("🚀 ~ handleChange ~ validateBank:", validateBank)
    if (parms === "Income") {
      try {
        if (validateIncome) {
          dispatch(tabsStateAction.Income(true));
          dispatch(tabsStateAction.IncomeErr(false));
          methods.clearErrors();
          return true;
        } else {
          submitBtnRef?.current?.click();
          dispatch(tabsStateAction.Income(false));
          dispatch(tabsStateAction.IncomeErr(true));
          return false;
        }
      } catch (error) {
        console.error(
          "🚀 ~ file: identification-details.js ~ line 709 ~ handleChange ~ error",
          error
        );
      }
    } else if (parms === "OtherIncome") {
      try {
        if (validateIncome) {
          if (validateOtherIncome) {
            dispatch(tabsStateAction.OtherIncome(true));
            dispatch(tabsStateAction.IncomeErr(false));
            methods.clearErrors();
            return true;
          } else {
            submitBtnRef?.current?.click();
            dispatch(tabsStateAction.OtherIncome(false));
            dispatch(tabsStateAction.IncomeErr(true));
            return false;
          }
        } else {
          submitBtnRef?.current?.click();
          dispatch(tabsStateAction.Income(false));
          dispatch(tabsStateAction.IncomeErr(true));
          return false;
        }
      } catch (error) {
        console.error(
          "🚀 ~ file: identification-details.js ~ line 709 ~ handleChange ~ error",
          error
        );
      }
    } else if (parms === "Bank") {
      try {
        if (validateIncome) {
          if (validateOtherIncome) {
            if (validateBank) {
              dispatch(tabsStateAction.Bank(true));
              dispatch(tabsStateAction.IncomeErr(false));
              methods.clearErrors();
              return true;
            } else {
              submitBtnRef?.current?.click();
              dispatch(tabsStateAction.Bank(false));
              dispatch(tabsStateAction.IncomeErr(true));
              return false;
            }
          } else {
            submitBtnRef?.current?.click();
            dispatch(tabsStateAction.OtherIncome(false));
            dispatch(tabsStateAction.IncomeErr(true));
            return false;
          }
        } else {
          submitBtnRef?.current?.click();
          dispatch(tabsStateAction.Income(false));
          dispatch(tabsStateAction.IncomeErr(true));
          return false;
        }
      } catch (error) {
        console.error(
          "🚀 ~ file: identification-details.js ~ line 709 ~ handleChange ~ error",
          error
        );
      }
    }
  };

  const { hash } = useLocation();
  useEffect(() => {
    if (!hash) return;
    if (hash == "#ei") {
      navigate(-1);
      return dispatch(tabsStateAction.Income(false));
    }
    if (hash == "#other-in") {
      navigate(-1);
      return handleChange("Income");
    }
    if (hash == "#bank-info") {
      navigate(-1);
      return handleChange("OtherIncome");
    }
    if (hash == "#od") {
      navigate(-1);
      return handleChange("Bank");
    }
  }, [hash]);
  
  return (
    <>
    {/* <JSONTree data={IncomeErr} /> */}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <fieldset disabled={viewOnlyFlag}>
            <div id="income-bank-tabs">
              {/* Here code  */}
              <div>
                <Accordion
                  expanded={!IncomeStatus}
                  onChange={() => {
                    dispatch(tabsStateAction.Income(false));
                  }}
                >
                  <AccordionSummary
                    expandIcon={!IncomeStatus || <AiOutlinePlus />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography>
                      {IncomeStatus && <BsCheck2Circle color="green" />}{" "}
                      Employment Income
                    </Typography>
                  </AccordionSummary>
                  <EmployementIncome checkValidation={handleSubmitValidation} />
                </Accordion>

                <Accordion
                  expanded={IncomeStatus && !OtherIncomeStatus}
                  onChange={() => {
                    handleChange("Income");
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      (IncomeStatus && !OtherIncomeStatus) || <AiOutlinePlus />
                    }
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                  >
                    <Typography>
                      {OtherIncomeStatus && <BsCheck2Circle color="green" />}{" "}
                      Other Income
                    </Typography>
                  </AccordionSummary>
                  <OtherIncome checkValidation={handleSubmitValidation} />
                </Accordion>

                <Accordion
                  expanded={OtherIncomeStatus && !BankStatus}
                  onChange={() => {
                    handleChange("OtherIncome");
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      (OtherIncomeStatus && !BankStatus) || <AiOutlinePlus />
                    }
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                  >
                    <Typography>
                      {BankStatus && <BsCheck2Circle color="green" />} Banking
                      Information
                    </Typography>
                  </AccordionSummary>
                  <BankingInformation
                    checkValidation={handleSubmitValidation}
                  />
                </Accordion>

                <Accordion
                  expanded={BankStatus && !OtherDocStatus}
                  onChange={() => {
                    handleChange("Bank");
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      (BankStatus && !OtherDocStatus) || <AiOutlinePlus />
                    }
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                  >
                    <Typography>
                      {OtherDocStatus && <BsCheck2Circle color="green" />} Other
                      documents
                    </Typography>
                  </AccordionSummary>
                  <OtherDocuments />
                </Accordion>

                <Snackbar
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  open={IncomeErr}
                  key={1}
                >
                  <Alert
                    onClick={() => {
                      dispatch(tabsStateAction.IncomeErr(false));
                    }}
                    severity="error"
                  >
                    {" "}
                    Answer the required field <CloseIcon />
                  </Alert>
                </Snackbar>
              </div>
              {/* end her  */}
            </div>

            <button
              ref={submitBtnRef}
              style={{ display: "none" }}
              type="submit"
              className="btn btn-info"
            >
              Next
            </button>
          </fieldset>
        </form>
        <DevTool control={methods.control} />
        <div className="mt-4">
          <SupportSection clickHere={clickHere} setClickHere={setClickHere} />
        </div>
        {/* grey area compare popup to get work done  */}
        {methods.watch("employment_details.employer")?.length ? (
          <>
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
            >
              <DialogTitle style={{ color: "red", textAlign: "center" }}>
                <AiOutlineCloseCircle
                  onClick={handleClose}
                  style={{ float: "left", color: "black" }}
                />
                Important Alert!
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <p>
                    Our system processed the document(s) you uploaded and found
                    one or more possible discrepancies. Please review and select
                    the correct information below.
                  </p>
                  <p style={{ textAlign: "center" }}>
                    <strong>
                      Your Monthly Gross / Pre-Tax Income with{" "}
                      {methods.getValues(
                        `employment_details.employer.${indexVerifyGross}.employer_name`
                      )}
                      :*
                    </strong>
                  </p>
                  <p style={{ textAlign: "center" }}>
                    Select the Correct Value
                  </p>
                </DialogContentText>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={5}>
                    <p>According to you:</p>
                  </Grid>
                  <Grid item xs={5}>
                    <p>According to your document:</p>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={5} className="p-1">
                    <button
                      className="btn btn-block btn-warning"
                      variant="contained"
                      onClick={() => {
                        methods.setValue(
                          `employment_details.employer.${indexVerifyGross}.gross_pay_verify`,
                          true,
                          true
                        );
                        setOpen(false);
                        setTimeout(() => {
                          refSubmitButtom?.current?.click();
                        }, 100);
                      }}
                    >
                      <NumberFormat
                        decimalScale={2}
                        value={
                          methods.getValues(
                            `employment_details.employer.${indexVerifyGross}.total_income`
                          ) || 0
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </button>
                  </Grid>
                  <Grid item xs={5} className="p-1">
                    <button
                      onClick={() => {
                        let _monthlyIncome =
                          methods.getValues(
                            `employment_details.employer.${indexVerifyGross}.monthly_avr_gross`
                          ) || 0;
                        const pay_frequency = methods.getValues(
                          `employment_details.employer[${indexVerifyGross}].pay_frequency`
                        ).value;
                        if (pay_frequency == "Weekly") {
                          _monthlyIncome = (_monthlyIncome / 52) * 12;
                        } else if (pay_frequency == "Bi-Weekly") {
                          _monthlyIncome = (_monthlyIncome / 26) * 12;
                        } else if (pay_frequency == "Monthly") {
                          //_monthlyIncome = _monthlyIncome;
                        } else if (pay_frequency == "Bi-Monthly") {
                          _monthlyIncome = (_monthlyIncome / 24) * 12;
                        } else if (pay_frequency == "One-Time") {
                          _monthlyIncome = _monthlyIncome * 12;
                        } else {
                          _monthlyIncome = _monthlyIncome * 12;
                        }
                        methods.setValue(
                          `employment_details.employer.${indexVerifyGross}.avrgross_pay`,
                          parseFloat(_monthlyIncome).toFixed(2),
                          true
                        );
                        methods.setValue(
                          `employment_details.employer.${indexVerifyGross}.gross_pay_verify`,
                          true,
                          true
                        );

                        if (pay_frequency == "Weekly") {
                          _monthlyIncome = (_monthlyIncome * 52) / 12;
                        } else if (pay_frequency == "Bi-Weekly") {
                          _monthlyIncome = (_monthlyIncome * 26) / 12;
                        } else if (pay_frequency == "Monthly") {
                          //_monthlyIncome = _monthlyIncome;
                        } else if (pay_frequency == "Bi-Monthly") {
                          _monthlyIncome = (_monthlyIncome * 24) / 12;
                        } else if (pay_frequency == "One-Time") {
                          _monthlyIncome = _monthlyIncome / 12;
                        }

                        methods.setValue(
                          `employment_details.employer.${indexVerifyGross}.total_income`,
                          parseFloat(_monthlyIncome).toFixed(2) || 0
                        );
                        methods.setValue(
                          `employment_details.employer.${indexVerifyGross}.total_annual_income`,
                          (parseFloat(_monthlyIncome).toFixed(2) || 0) * 12
                        );

                        setOpen(false);
                        setTimeout(() => {
                          refSubmitButtom?.current?.click();
                        }, 100);
                      }}
                      className="btn btn-block btn-success"
                    >
                      <NumberFormat
                        decimalScale={2}
                        value={methods.getValues(
                          `employment_details.employer.${indexVerifyGross}.monthly_avr_gross`
                        )}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </button>
                  </Grid>
                  <Grid item xs={12} className="p-1"></Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                ></Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={1}>
                    {/* <p>OR</p> */}
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={4}>
                    <button
                      onClick={() => {
                        setOpen1(true);
                      }}
                      className="btn btn-link"
                    >
                      Or enter a different value
                    </button>
                  </Grid>
                </Grid>
              </DialogContent>
            </Dialog>

            <Dialog
              TransitionComponent={Transition}
              keepMounted
              open={open1}
            // onClose={handleClose1}
            >
              {open1 && (
                <DialogContent>
                  <InputCurrencyField
                    label="Gross Pay (Pre Tax)"
                    register={methods.register(
                      `employment_details.employer.${indexVerifyGross}.new_avrgross_pay`,
                      { required: true }
                    )}
                  />
                </DialogContent>
              )}
              <DialogActions>
                <Button
                  disableRipple
                  onClick={() => {
                    setOpen1(false);
                  }}
                >
                  Back
                </Button>

                <Button
                  color="success"
                  disableRipple
                  onClick={() => handleSave()}
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </>
        ) : null}

        {/* end  grey area compare popup to get work done  */}
      </FormProvider>
      <ToastContainer />
    </>
  );
}

export default IncomeBankingOtherForm;
