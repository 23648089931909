import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import {
  useDeleteSourceMutation,
  useGetIncomeBankingQuery,
  useGetvalidationCheckBankQuery,
} from "../../../../services/identificationApi";
import { useSelector } from "react-redux";
import { TiDelete } from "react-icons/ti";
import SelectField from "../../../registration/Inputs/SelectField";
import InputCurrencyField from "../../../registration/Inputs/InputCurrencyField";
import InputField from "../../../registration/Inputs/InputField";
import UploadFile from "../../../common/uploadFile/uploadFile";
import CustomRadioButton from "../../../registration/Inputs/customRadioButton";
import FileIcon from "../../../common/FileIcon";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import { RiInformationLine } from "react-icons/ri";
import { ErrorMessage } from "@hookform/error-message";
import RadioInputField from "../../../registration/Inputs/RadioInputField";
import { useGetEmailListQuery } from "../../../../services/mlApi";
import { useDocumentContext } from "../../../../PrivateRoute";

const Source = ({ field, remove, index }) => {
  const { register, watch, getValues, formState, setValue } = useFormContext();
  const [doDelete, deleteResult] = useDeleteSourceMutation();
  const [open, setOpen] = React.useState(false);
  const [bankStatementInformation, setBankStatementInformation] =
    React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  const useWindowWidth = useSelector(
    (state) => state.identification.sideMenuSlice
  );
  const { documentData:documentWiseRules, sectionData:paystubWiseRules, identificationData:identificationWiseRules } = useDocumentContext();

  const { data: validationCheck } = useGetvalidationCheckBankQuery(trn_id);
  const { data: getEmailList } = useGetEmailListQuery();
  // useEffect(() => {
  //   const subscribe = watch((value, { name, type }) => {
  //     console.log("Check Val:46", value, name, type);
  //     setValue(name)
  //   });
  //   return () => subscribe.unsubscribe();
  // }, [watch]);

  const { data } = useGetIncomeBankingQuery(trn_id);
  return (
    <div>
      <div className="col-sm-12">
        <div className="blue-box pd15 mr-t20">
          <div className="row">
            <div className="col-sm-12">
              <span className="s-no-index float-left">{index + 1}</span>{" "}
              {deleteResult.isUninitialized ? (
                <button
                  type="button"
                  onClick={async () => {
                    setOpen(true);
                  }}
                  title="Delete this Employer"
                  className="float-right btn btn-sm btn-danger"
                >
                  <TiDelete size={25} /> Delete
                </button>
              ) : (
                <button disabled className="float-right btn btn-sm btn-danger">
                  <TiDelete size={25} /> Deleting...
                </button>
              )}
            </div>
          </div>
          {/* applicationProfileSettings.incomeNBanking.bankName !== "Hide" &&  */}
          <div className="row">
            {<div className="col-sm-12 col-md-6 col-lg-4">
              <div className="form-group cus-input-group mr-b20">
                <InputField
                  label="Bank Name"
                  register={register(
                    `banking_details.banking[${index}].bank_name`
                  )}
                />
              </div>
            </div>}
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="form-group cus-input-group mr-b20">
                <SelectField
                  options={[
                    { label: "Checking", value: "Checking" },
                    { label: "Savings", value: "Savings" },
                    { label: "Other", value: "Investments/Other" },
                  ]}
                  label="Account Type"
                  register={register(
                    `banking_details.banking[${index}].account_type`
                  )}
                />
              </div>
            </div>
            {paystubWiseRules?.MonthlyAmount !== "Hide" && <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="form-group cus-input-group mr-b30">
                <InputCurrencyField
                  notRequired={paystubWiseRules?.MonthlyAmount!='Required'}
                  label="Enter Current Balance here"
                  suffix="$"
                  register={register(
                    `banking_details.banking[${index}].current_balance`
                  )}
                  placeholder={"Enter Balance"}
                />
              </div>
            </div>}
          </div>
          <div className="row">
            {documentWiseRules?.BankStatements != 'Hide' && <div className="col-sm-12 col-md-12 col-lg-7">
              <CustomRadioButton
                notRequired={!(documentWiseRules?.BankStatements == 'Required')}
                disable={getValues(
                  `banking_details.banking.${index}.fileCount`
                )}
                register={register(
                  `banking_details.banking.${index}.availableToUpload`
                )}
                label="Do you have PDF bank statement(s) available to upload ?"
              />
            </div>}
            {watch(`banking_details.banking.${index}.availableToUpload`) ==
              "Yes" && (
                <>
                  <div className="col-sm-12 col-md-8 col-lg-9">
                    <div className="ssn-text-block">
                      <h4>
                        Bank Statement:
                        <span className="red">*</span>
                        {/* <button type="button" className="info-btn">
                          <img src="images/info-icon.png" alt="" />
                        </button> */}
                        <RiInformationLine
                          className="ms-2 cursor-pointer"
                          fill="#D3AF51"
                          size={20}
                          onClick={() => setBankStatementInformation(true)}
                        />
                      </h4>

                      <h5>(covering 2 most recent months)</h5>
                      <small
                        style={{
                          background: "yellow",
                          color: "black",
                          margin: "4px",
                        }}
                      >
                        IMPORTANT! PDF Bank Statements downloaded directly from
                        bank website ONLY! Please do NOT upload pictures of bank
                        statements. Click "i" button above for full details.
                      </small>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-3 d-flex justify-content-center align-items-center mt-4">
                    <div className="ssn-btn">
                      <UploadFile
                        onlyPdf={true}
                        label="Upload Back"
                        className="mr-b10"
                        documentId={6}
                        SourceId={index + 1}
                      />
                    </div>
                  </div>
                  <div
                    className={` ${useWindowWidth.windowWidth < 986 && `justify-content-center`
                      } d-flex flex-wrap`}
                  >
                    {data?.banking_details?.banking[index]?.files.map(
                      (item, index) => {
                        return (
                          <FileIcon
                            key={item.application_document_id}
                            fileObj={item}
                          />
                        );
                      }
                    )}
                  </div>
                </>
              )}

            {watch(`banking_details.banking.${index}.availableToUpload`) ==
              "No" && paystubWiseRules?.MonthlyAmount !== "Hide" &&  (
                <>
                  {" "}
                  <RadioInputField
                    options={[
                      {
                        value:
                          "I don't currently have access to document(s), but can provide later",
                      },
                      {
                        value: "I do not have proof of bank account",
                      },
                      {
                        value: "other",
                        label: "Other"
                      },
                    ]}
                    name={`banking_details.banking.${index}.no_doc_reason`}
                  />
                  {watch(`banking_details.banking.${index}.no_doc_reason`) ===
                    "other" && (
                      <div>
                        <InputField
                          label="Please Explain"
                          register={register(
                            `banking_details.banking.${index}.other_reason`
                          )}
                          placeholder="Please Explain"
                        />
                      </div>
                    )}
                </>
              )}
            <Stack
              sx={{ width: "100%" }}
              style={{ textTransform: "capitalize", marginTop: "10px" }}
              spacing={2}
            >
              <ErrorMessage
                errors={formState.errors}
                name={`banking_details.banking.${index}.files`}
                render={({ message }) => {
                  if (message) return <Alert severity="error">{message}</Alert>;

                  return <></>;
                }}
              />
            </Stack>
            {watch(`banking_details.banking.${index}.files`).length > 0 &&
              <Stack sx={{ width: "100%" }} style={{ marginTop: "10px" }} spacing={2}>
                <div className="mt-1 mb-1">
                  {validationCheck?.data?.validation_messages?.[`source_${index + 1}`]?.map(item => item.applicant)?.filter(item => !item.isDeleted)?.map((item) =>
                    item.message && <Alert severity="warning">{item.message}</Alert>
                  )}
                  {/* {getValues(
            `employment_details.employer.${index}.validation_message_status`
          ).map(
            (item) =>
              item.message && <Alert severity="warning">{item.message}</Alert>
          )} */}
                </div>
              </Stack>}
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={() => { }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Are you sure want to delete non-employment  ${index + 1} ?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Warning! Once deleted cannot revert back.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
          >
            No
          </Button>
          <Button
            onClick={async () => {
              if (isDeleting) return;
              // setTimeout(() => {
              //   remove(index);
              // }, 500);
              setIsDeleting(true);
              setOpen(false);
              await doDelete({
                trn_id: trn_id,
                source_id: index + 1,
                document_id: "6",
              })
                .then((deleteDocResponse) => {
                  if (
                    deleteDocResponse.data.status === 200 &&
                    getValues(`banking_details.banking`)?.length === 1
                  ) {
                    setValue(`banking_details.response`, "No");
                  }
                })
                .catch((deleteDocError) =>
                  console.log(
                    "Delete Doc Error in Banking Source",
                    deleteDocError
                  )
                )
                .finally(() => {
                  setIsDeleting(false); // Reset the flag after deletion
                });
              // setOpen(false);
            }}
            autoFocus
            disabled={isDeleting}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={bankStatementInformation}
        onClose={() => {
          setBankStatementInformation(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <strong>IMPORTANT!</strong> If you did not upload original PDF’s of
            your earnings statements, are self-employed, or indicated any
            non-employment income, then for verification purposes, you will need
            to upload PDF’s of your 2 most recent personal bank statements
            downloaded directly from your online bank account website. Please do
            NOT upload scanned images, redacted documents, or screen shots of
            documents, and please do NOT “print to PDF”, “save as” or modify
            these documents in any way, as these documents cannot be verified by
            our system. If you are self-employed, PDF’s of your 2 most recent
            personal bank statements are required. If you have business bank
            statements, you can include them, but personal bank statements are
            required to verify self-employment income.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              setBankStatementInformation(false);
            }}
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

Source.propTypes = {};

export default Source;
