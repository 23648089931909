import React, { useEffect, useState, useOutletContext, useRef } from "react";
import Address from "./address/address";
import PersonalDetails from "./personal-details/personal-details";
import ProofOfIdentity from "./proof-of-identity/proof-of-identity";
import Ssn from "./ssn/ssn";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DevTool } from "@hookform/devtools";
import {
  useGetIdentificationDataQuery,
  useGetSectionStatusQuery,
  useSaveIdDetailsMutation,
} from "../../../services/identificationApi";
import { toast, ToastContainer } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { sideNavAction } from "../../../store/identification/sideMenuSlice";
import { formSubmitStatusRest } from "../../../store/registration/fromSubmitSlice";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import {
  Accordion,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  AccordionDetails,
  Snackbar,
} from "@mui/material";
import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BsCheck2Circle } from "react-icons/bs";
import { tabsStateAction } from "../../../store/identification/tabStateSlice";
import MuiAlert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import SupportSection from "../../common/SupportSection/SupportSection";
import { RiInformationLine } from "react-icons/ri";
import { JSONTree } from "react-json-tree";
import { useDocumentContext } from "../../../PrivateRoute";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function validateDOB(dob) {
  var today = new Date();
  var minAge = new Date(
    today.getFullYear() - 100,
    today.getMonth(),
    today.getDate()
  );
  var maxAge = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  );

  if (dob > maxAge || dob < minAge) {
    return false;
  }
  return true;
}
const IdentificationDetails = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [licenseInformation, setLicenseInformation] = React.useState(false);

  const submitBtnRef = useRef(null);
  const [selectedName, setSelectedName] = useState(null);
  const [selectedDOB, setSelectedDOB] = useState(null);
  const [open, setOpen] = useState(false);
  //*********FOR PERSONAL DETAILS SIDE COMPLETION***********//
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailIDError, setEmailIdError] = useState(false);
  const [mobileNoError, setMobileNoError] = useState(false);
  const [dobError, setDobError] = useState(false);
  const [aliasFirstNameError, setAliasFirstNameError] = useState(false);
  const [aliasLastNameError, setAliasLastNameError] = useState(false);
  const [ssnError, setSSNError] = useState(false);
  const [clickHere, setClickHere] = useState(true);

  const [saveIdDetails, result] = useSaveIdDetailsMutation();
  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );

  const personalDetailsStatus = useSelector(
    (state) => state.identification.tabsStateSlice.personalDetailsStatus
  );

  const personalErr = useSelector(
    (state) => state.identification.tabsStateSlice.personalErr
  );

  const ProofOfIdentityStatus = useSelector(
    (state) => state.identification.tabsStateSlice.ProofOfIdentityStatus
  );
  const AddressStatus = useSelector(
    (state) => state.identification.tabsStateSlice.AddressStatus
  );

  const SsnStatus = useSelector(
    (state) => state.identification.tabsStateSlice.SsnStatus
  );

  const { data } = useGetSectionStatusQuery(trn_id);

  const nameMatched = useSelector(
    (state) => state.identification.sideMenuSlice
  );
  const getIdentificationData = useGetIdentificationDataQuery(trn_id);

  const formSectionSubmit = useSelector(
    (state) => state.formSubmit.fromSectionSubmit
  );
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (formSectionSubmit) {
        if (!(await handleSubmitValidation("personalDetails"))) return;
        if (!(await handleSubmitValidation("ProofOfIdentity"))) return;
        if (!(await handleSubmitValidation("Address"))) return;
        if (!(await handleSubmitValidation("SSN"))) return;
        submitBtnRef?.current?.click();
      }
    })();
  }, [formSectionSubmit]);

  const viewOnlyFlag = useSelector((state) => state.formSubmit.viewOnlyFlag);

  const { documentData:documentWiseRules, sectionData:paystubWiseRules, identificationData:identificationWiseRules } = useDocumentContext();

  
  yup.addMethod(yup.array, "uniquePhone", function (message) {
    return this.test("unique", message, function (data) {
      const list = data;
      let isUnique = true;
      let row = list?.length;
      for (let i = 0; i < row; i++) {
        for (let j = i + 1; j < row; j++) {
          if (JSON.stringify(list[i]) === JSON.stringify(list[j])) {
            isUnique = false;
          }
        }
      }
      if (isUnique) {
        return true;
      }
      return false;
    });
  });

  const notEmptyFieldsMethod = (
    value,
    path,
    createError,
    myFailureMessage,
    setNameError
  ) => {
    try {
      if (value) {
        console.log("Value For Mobile Number TEst 112", value);
        setNameError(false);
        return true;
      }
      console.log("Value For Mobile Number TEst 116", value, setNameError);

      setNameError(true);
      return createError({ path, message: myFailureMessage });
    } catch (error) {
      console.error("🚀 ~ file: identification.js ~ line 96 ~ error", error);
    }
  };

  const validateSSNFormates = (ssnNo, path, createError, myFailureMessage) => {
    try {
      switch (ssnNo) {
        case "111-11-1111":
          setSSNError(true);
          return createError({ path, message: myFailureMessage });
        case "222-22-2222":
          setSSNError(true);
          return createError({ path, message: myFailureMessage });
        case "333-33-3333":
          setSSNError(true);
          return createError({ path, message: myFailureMessage });
        case "444-44-4444":
          setSSNError(true);
          return createError({ path, message: myFailureMessage });
        case "555-55-5555":
          setSSNError(true);
          return createError({ path, message: myFailureMessage });
        case "666-66-6666":
          setSSNError(true);
          return createError({ path, message: myFailureMessage });
        case "777-77-7777":
          setSSNError(true);
          return createError({ path, message: myFailureMessage });
        case "888-88-8888":
          setSSNError(true);
          return createError({ path, message: myFailureMessage });
        case "999-99-9999":
          setSSNError(true);
          return createError({ path, message: myFailureMessage });
        case "123-45-6789":
          setSSNError(true);
          return createError({ path, message: myFailureMessage });
        default:
          return true;
      }
    } catch (error) {
      console.error("🚀 ~ file: identification.js ~ line 160 ~ error", error);
    }
  };

  const ssn_data_schema = yup.object().shape({
    isUS_citizen:  yup
    .string()
    .nullable()  // Allow nullable in case it's not required
    .when([], {
      is: () => true,  //identificationWiseRules?.ssnnumber === 'Required',
      then: yup
        .string()
        .typeError("SSN is required")
        .required("SSN is required to be uploaded"),
      otherwise: yup
        .string()
        .nullable(),  // If not required, allow it to be null
    }),
    SSNNumber: yup
      .string()
      .nullable()

      .when("isUS_citizen", {
        is: (value) => {
          return value === "Yes";
        },
        then: yup
          .string()
          .typeError("Required")
          .required("Required")
          .matches(
            "^(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$",
            "Please Enter valid SSN Number"
          )
          .test("validate-ssn", function (value) {
            const { path, createError } = this;
            const myFailureMessage = "Please Enter valid SSN Number";
            try {
              if (value) {
                return validateSSNFormates(
                  value,
                  path,
                  createError,
                  myFailureMessage
                );
              }
            } catch (error) {
              console.log("SSN YUP ERROR:429", error);
            }
          }),
      }),
    availableToUpload_ssn:  yup
    .string()
    .nullable()  // Allow nullable in case it's not required
    .when([], {
      is: () => documentWiseRules?.SSNCard === 'Required',
      then: yup
        .string()
        .typeError("SSN is required")
        .required("SSN is required to be uploaded"),
      otherwise: yup
        .string()
        .nullable(),  // If not required, allow it to be null
    }),
    file: yup
      .string()
      .nullable()
      .typeError("front file required")
      .when("availableToUpload_ssn", {
        is: (value) => {
          return value == "Yes";
        },
        then: yup
          .string()
          .typeError("SSN file is required")
          .required("SSN file is required"),
      }),
    other_reason: yup.string().when("no_doc_reason", {
      is: (value) => {
        return value == "other";
      },
      then: yup
        .string()
        .typeError("Required")
        .required("Required")
        .matches(/^$|^\S+.*/g, "Enter a valid value"),
      otherwise: yup.string().nullable(),
    }),
  });

  const address_data_schema = yup.array().of(
    yup.object().shape({
      street_address: yup
        .string()
        .trim()
        .typeError("Required")
        .required("Required")

        .matches(/^$|^\S+.*/g, "Enter a valid value"),
      appartment: yup
        .string()
        .nullable()

        .matches(/^$|^\S+.*/g, "Enter a valid value"),
      city: yup
        .string()
        .required("Required")
        .min(2, "minimum 2 characters is required")

        .matches(/^$|^\S+.*/g, "Enter a valid value"),
      state: yup
        .object({
          value: yup.string().required("Required"),
          label: yup.string().required("Required"),
        })
        .required("Required"),
      zipcode: yup
        .string()
        .typeError("Required")
        .matches(
          /^(\d{4}[0-9]|\d{8}[0-9]|\d{4}[0-9]-\d{3}[0-9]|-----\d{3}[0-9])$/g,
          "Please enter valid zip code"
        )
        .required("Required"),
      move_in_date: yup
        .string()
        .nullable()
        .when("is_current_address", {
          is: (value) => {
            return !!value &&  identificationWiseRules?.MoveInDate === 'Required';
          },
          then: yup.string().typeError("Required").required("Required"),
        }),

      move_out_date: yup
        .string()
        .nullable()
        .when("is_current_address", {
          is: (value) => {
            return value == false && identificationWiseRules?.IfPreviousMoveOutDate === 'Required';
          },
          then: yup
            .string()
            .typeError("Required")
            .required("Required")
            .test(
              "move_out_date_compare",
              "the date must be greater than the move-in date",
              function (value, context) {
                const { move_in_date } = this.parent;
                const dateConvertMoveIn = new Date(move_in_date);
                const dateConvertMoveOut = new Date(value);
                return (
                  dateConvertMoveOut.getTime() >= dateConvertMoveIn.getTime()
                );
              }
            ),
        }),

      reason: yup
        .string()
        .nullable()
        .typeError("Required")

        .matches(/^$|^\S+.*/g, "Enter a valid name")
        .when([], {
          is: () => identificationWiseRules?.ReasonforMoving === 'Required',
          then: yup.string().required("Reason is required").max(500, "maximum 500 characters is required"),
          otherwise: yup.string().nullable(),
        }),
      landlord_name: yup
        .string()
        .nullable()
        .when("property_type", {
          is: (value) => {
            return value == 1;
          },
          then: yup
            .string()
            .typeError("Required")
            .nullable()
            .required("Required")

            .matches(/^$|^\S+.*/g, "Enter a valid value")
            .min(2, "minimum 2 characters is required"),
        }),
      landlord_number: yup
        .string()
        .nullable()
        .when("property_type", {
          is: (value) => {
            return value == 1 && identificationWiseRules?.LandlordTelephone === 'Required';
          },
          then: yup
            .string()
            .min(11, "The value is not a valid Phone Number")
            .typeError("Required")
            .nullable()
            .required("Required"),
        }),
      current_rent: yup
        .string()
        .nullable()
        .when("property_type", {
          is: (value) => {
            return value == 1;
          },
          then: yup
            .string()
            .matches(/^(\d*[0-9])/g, "Only Numbers Allowed")
            .matches(/^$|^\S+.*/g, "Enter a valid value")
            .typeError("Required")
            .required("Required"),
        }),
      mortgage_rent: yup
        .string()
        .nullable()
        .when("property_type", {
          is: (value) => {
            return value == 2;
          },
          then: yup
            .string()
            .nullable()
            .matches(/^(\d*[0-9])/g, "Only Numbers Allowed")
            .matches(/^$|^\S+.*/g, "Enter a valid value")
            .typeError("Required")
            .required("Required"),
        }),
      mortgage_company: yup
        .string()
        .nullable()
        .when("property_type", {
          is: (value) => {
            return value == 2;
          },
          then: yup
            .string()

            .matches(/^$|^\S+.*/g, "Enter a valid value")
            .typeError("Required")
            .required("Required"),
        }),
    })
  );
  const identification_data_schema = yup.object().shape({
    documenttype_id:yup.number(),
    availableToUpload_id:  yup
    .string()
    .nullable()  // Allow nullable in case it's not required
    .when([], {
      is: () => (documentWiseRules?.PermanentResidentID=="Required" || documentWiseRules?.Visa =="Required" || documentWiseRules?.USDriversLicense=="Required" || documentWiseRules?.Passport=="Required" ),
      then: yup
        .string()
        .typeError("required")
        .required("required"),
      otherwise: yup
        .string()
        .nullable(),  // If not required, allow it to be null
    }),
    file: yup
      .string()
      .nullable()
      .typeError("front file Required")
      .when("availableToUpload_id", {
        is: (value) => {
          return value == "Yes";
        },
        then: yup
          .string()
          .typeError("front file required")
          .required("front file required"),
      }),

      backfile: yup.string().when("availableToUpload_id", {
        is: (value) => {
          return value == "Yes";
        },
        then: yup.string().when("documenttype_id", {
          is: (value) => {
            return value == "2" || value == "13";
          },
          then: yup.string()
            .required("required")
            .typeError("Please upload back file"),
          otherwise: yup.string().nullable(),
        }),
        otherwise: yup.string().nullable(),
      }),
  });
  const applicant_data_schema = yup.object().shape({
    first_name: yup
      .string()
      .typeError("Required")

      .matches(/^$|^\S+.*/g, "Enter a valid value")
      .test("is_empty_field", function (value) {
        const { path, createError } = this;
        const myFailureMessage = "Required";
        return notEmptyFieldsMethod(
          value,
          path,
          createError,
          myFailureMessage,
          setFirstNameError
        );
      })
      .required("Required")
      .min(2, "minimum 2 characters is required"),
    middle_name: yup
      .string()
      .nullable(),
    last_name: yup
      .string()

      .matches(/^$|^\S+.*/g, "Enter a valid value")
      .typeError("Required")
      .test("is_empty_field", function (value) {
        const { path, createError } = this;
        const myFailureMessage = "Required";
        return notEmptyFieldsMethod(
          value,
          path,
          createError,
          myFailureMessage,
          setLastNameError
        );
      })
      .required("Required")
      .min(2, "minimum 2 characters is required"),
    mobile: yup
      .array()
      .of(
        yup.object().shape({
          number: yup
            .string()
            .typeError("Required")
            .required("Required")
            .test("is_empty_field", function (value) {
              const { path, createError } = this;
              const myFailureMessage = "Invalid phone number";
              return notEmptyFieldsMethod(
                value,
                path,
                createError,
                myFailureMessage,
                setMobileNoError
              );
            })
            .min(11, "The value is not a valid Phone Number"),
        })
      )
      .min(1, "must have atleast 1 records")
      .uniquePhone("You cannot add duplicate phone number"),

    DOB: yup
      .string()
      .typeError("Required")
      .required("Required"),
    // other_reason: yup.string().when("availableToUpload_id", {
    //   is: (value) => {
    //     return value == "No";
    //   },
    //   then: yup.string().when("no_doc_reason", {
    //     is: (value) => {
    //       return value == "Other";
    //     },
    //     then: yup.string().typeError("required").required("Required")
    //       .matches(/^$|^\S+.*/g, "Enter a valid value"),
    //     otherwise: yup.string().nullable(),
    //   }),
    //   otherwise: yup.string().nullable(),
    // }),
    otherPhoneCaption: yup.object().nullable(),
    Alias: yup.array().of(
      yup.object().shape({
        fname: yup
          .string()
          .test("is_empty_field", function (value) {
            const { path, createError } = this;
            const myFailureMessage = "Required";
            return notEmptyFieldsMethod(
              value,
              path,
              createError,
              myFailureMessage,
              setAliasFirstNameError
            );
          })
          .typeError("Required")
          .required("Required")

          .matches(/^$|^\S+.*/g, "Enter a valid value"),
        lname: yup
          .string()
          .test("is_empty_field", function (value) {
            const { path, createError } = this;
            const myFailureMessage = "Required";
            return notEmptyFieldsMethod(
              value,
              path,
              createError,
              myFailureMessage,
              setAliasLastNameError
            );
          })

          .matches(/^$|^\S+.*/g, "Enter a valid value")
          .typeError("Required")
          .required("Required"),
      })
    ),
  });

  // this schema is same as proof of ids , remove doc upload part
  const personaldetails_data_schema = yup.object().shape({
    first_name: yup
      .string()
      .typeError("Required")

      .matches(/^$|^\S+.*/g, "Enter a valid value")
      .test("is_empty_field", function (value) {
        const { path, createError } = this;
        const myFailureMessage = "Required";
        return notEmptyFieldsMethod(
          value,
          path,
          createError,
          myFailureMessage,
          setFirstNameError
        );
      })
      .required("Required")
      .min(2, "minimum 2 characters is required"),
    middle_name: yup
      .string()
      .nullable()
    ,
    last_name: yup
      .string()

      .matches(/^$|^\S+.*/g, "Enter a valid value")
      .typeError("Required")
      .test("is_empty_field", function (value) {
        const { path, createError } = this;
        const myFailureMessage = "Required";
        return notEmptyFieldsMethod(
          value,
          path,
          createError,
          myFailureMessage,
          setLastNameError
        );
      })
      .required("Required")
      .min(2, "minimum 2 characters is required"),
    mobile: yup
      .array()
      .of(
        yup.object().shape({
          number: yup
            .string()
            .typeError("Required")
            .required("Required")
            .test("is_empty_field", function (value) {
              const { path, createError } = this;
              const myFailureMessage = "Invalid phone number";
              return notEmptyFieldsMethod(
                value,
                path,
                createError,
                myFailureMessage,
                setMobileNoError
              );
            })
            .min(11, "The value is not a valid Phone Number"),
        })
      )
      .min(1, "must have atleast 1 records")
      .uniquePhone("You cannot add duplicate phone number"),

    DOB: yup
      .string()
      .typeError("Required")
      .required("Required"),
    other_reason: yup.string().when("availableToUpload_id", {
      is: (value) => {
        return value == "No";
      },
      then: yup.string().when("no_doc_reason", {
        is: (value) => {
          return value == "other";
        },
        then: yup
          .string()
          .typeError("Required")
          .required("Required")
          .matches(/^$|^\S+.*/g, "Enter a valid value"),
        otherwise: yup.string().nullable(),
      }),
      otherwise: yup.string().nullable(),
    }),
    otherPhoneCaption: yup.object().nullable(),
    Alias: yup.array().of(
      yup.object().shape({
        fname: yup
          .string()
          .test("is_empty_field", function (value) {
            const { path, createError } = this;
            const myFailureMessage = "Required";
            return notEmptyFieldsMethod(
              value,
              path,
              createError,
              myFailureMessage,
              setAliasFirstNameError
            );
          })
          .typeError("Required")
          .required("Required")

          .matches(/^$|^\S+.*/g, "Enter a valid value"),
        lname: yup
          .string()
          .test("is_empty_field", function (value) {
            const { path, createError } = this;
            const myFailureMessage = "Required";
            return notEmptyFieldsMethod(
              value,
              path,
              createError,
              myFailureMessage,
              setAliasLastNameError
            );
          })

          .matches(/^$|^\S+.*/g, "Enter a valid value")
          .typeError("Required")
          .required("Required"),
      })
    ),
    // file: yup
    //   .string()
    //   .nullable()
    //   .typeError("front file required")
    //   .when("availableToUpload_id", {
    //     is: (value) => {
    //       return value == "Yes";
    //     },
    //     then: yup
    //       .string()
    //       .typeError("front file required")
    //       .required("front file required"),
    //   }),
  });

  const schema = yup.object().shape({
    applicant: applicant_data_schema,
    identification_data: identification_data_schema,
    address: address_data_schema,
    ssn_data: ssn_data_schema,
  });



  const methods = useForm({
    resolver: !viewOnlyFlag && yupResolver(schema),
    mode: "onChange",
  });
  const { setValue, getValues, watch } = methods;
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userDOB, setUserDOB] = useState("");


  

 


  useEffect(() => {


    const data_form_value = methods.watch();

    const api_data = getIdentificationData?.data;
  
    if (Object.keys(data_form_value)?.length) {
     
      const mapData = {
        ...api_data,

        identification_data: {
          ...api_data?.identification_data,
            availableToUpload_id: !data_form_value?.identification_data?.availableToUpload_id?.length
              ? api_data?.identification_data?.availableToUpload_id
              : data_form_value?.identification_data?.availableToUpload_id,
        },
        address: !data_form_value?.address?.length
          ? api_data?.address
          : data_form_value?.address,

        applicant: {
          ...api_data?.applicant,
          alias_status: !data_form_value?.applicant.alias_status
            ? api_data?.applicant?.alias_status
            : data_form_value?.applicant?.alias_status,
          Alias: !data_form_value?.applicant?.Alias?.length
            ? api_data?.applicant.Alias
            : data_form_value?.applicant.Alias,
          DOB: !data_form_value?.applicant.DOB
            ? api_data?.applicant.DOB
            : data_form_value?.applicant.DOB,
          middle_name: !data_form_value?.applicant.middle_name
            ? api_data?.applicant.middle_name
            : data_form_value?.applicant.middle_name,
          first_name: !data_form_value?.applicant.first_name
            ? api_data?.applicant.first_name
            : data_form_value?.applicant.first_name,
          last_name: !data_form_value?.applicant.last_name
            ? api_data?.applicant.last_name
            : data_form_value?.applicant.last_name,
          mobile: !data_form_value?.applicant.mobile?.length
            ? api_data?.applicant.mobile
            : data_form_value?.applicant.mobile,
          otherPhoneCaption: !data_form_value?.applicant.otherPhoneCaption
            ? api_data?.applicant.otherPhoneCaption
            : data_form_value?.applicant.otherPhoneCaption,
          otherPhoneNumber: !data_form_value?.applicant.otherPhoneNumber
            ? api_data?.applicant.otherPhoneNumber
            : data_form_value?.applicant.otherPhoneNumber,
      

        },
        ssn_data: {
          ...api_data?.ssn_data,
          SSNNumber: !data_form_value?.ssn_data?.SSNNumber
            ? api_data?.ssn_data?.SSNNumber
            : data_form_value?.ssn_data?.SSNNumber,
          isUS_citizen: !data_form_value?.ssn_data?.isUS_citizen
            ? api_data?.ssn_data?.isUS_citizen
            : data_form_value?.ssn_data?.isUS_citizen,
          availableToUpload_ssn: !data_form_value?.ssn_data.availableToUpload_ssn
            ? api_data?.ssn_data?.availableToUpload_ssn
            : data_form_value?.ssn_data?.availableToUpload_ssn,
        },
      };
     
      methods.reset(mapData);
      setUserFirstName(
        !data_form_value?.applicant.first_name
          ? api_data?.applicant.first_name
          : data_form_value?.applicant.first_name
      );
      setUserLastName(
        !data_form_value?.applicant.last_name
          ? api_data?.applicant.last_name
          : data_form_value?.applicant.last_name
      );
      setUserDOB(
        !data_form_value?.applicant.DOB ? (api_data?.applicant.DOB)
          : data_form_value?.applicant.DOB
      );
    }
    else {
      methods.reset(api_data);
    }
  }, [getIdentificationData.data, methods]);

  // const watch = methods.watch;

  useEffect(() => {
    //FOR PERSONAL DETAILS SUB SECTION COMPLETION
    console.log("METHOD ERROR INVOKE Line:504", firstNameError, lastNameError);
    if (firstNameError) {
      console.log("FirstNameError ::508", firstNameError);
      dispatch(sideNavAction.firstnameError(true));
    }
    if (lastNameError) {
      dispatch(sideNavAction.lastnameError(true));
    }
    if (mobileNoError) {
      dispatch(sideNavAction.mobilenumberError(true));
    }
    if (dobError) {
      dispatch(sideNavAction.dateofbirthError(true));
    }
    if (aliasFirstNameError) {
      dispatch(sideNavAction.aliasfirstnameError(true));
    }
    if (aliasLastNameError) {
      dispatch(sideNavAction.aliaslastnameError(true));
    }
    if (!firstNameError && !lastNameError && !mobileNoError) {
      if (watch("applicant.alias_status") === "Yes") {
        if (!aliasFirstNameError && !aliasLastNameError) {
          dispatch(sideNavAction.aliasfirstnameError(false));

          dispatch(sideNavAction.aliaslastnameError(false));
        }
      }
      dispatch(sideNavAction.firstnameError(false));
      dispatch(sideNavAction.lastnameError(false));
      dispatch(sideNavAction.mobilenumberError(false));
      dispatch(sideNavAction.dateofbirthError(false));
    }
    // dispatch(sideNavAction.personalDetailStatus(true));
  }, [
    firstNameError,
    lastNameError,
    mobileNoError,
    dobError,
    aliasFirstNameError,
    aliasLastNameError,
    dispatch,
    watch,
  ]);

  const onSubmitDetails = async (fromData) => {
    if (fromData?.ssn_data?.no_doc_reason === "other") {
      fromData.ssn_data.no_doc_reason = fromData?.ssn_data?.other_reason;
    }

    if (fromData?.identification_data?.no_doc_reason === "other") {
      fromData.identification_data.no_doc_reason =
        fromData?.identification_data?.other_reason;
    }

    setSelectedName(null);
    setSelectedDOB(null);
    if (fromData.address?.length > 0) {
      for (let index = 0; index < fromData.address.length; index++) {
        fromData.address[index].ID = fromData.address[index].ID
          ? fromData.address[index].ID
          : 0;
      }
    }
    const payload = {
      trn_id,
      ...fromData,
    };
    const response = await toast.promise(saveIdDetails(payload), {
      pending: "Saving",
      error: "Saving failed 🤯",
    });
    if (response) {
      dispatch(formSubmitStatusRest());
      dispatch(sideNavAction.sideNav("applicant"));
      dispatch(tabsStateAction.sideNavIdentificationReducer(true));
      navigate("applicantDetail");
    }
  };

  useEffect(() => {
    document.title = "Identification Details";
  }, []);

  useEffect(() => {
    dispatch(tabsStateAction.sideNavIdentificationReducer(false));
  }, []);

  const onSubmit = async (fromData) => {
    const postData = {
      ...fromData,
      identification_data: {
        ...fromData?.identification_data,
        other_reason:
          fromData?.identification_data?.availableToUpload_id == "No" &&
            fromData?.identification_data?.no_doc_reason == "other"
            ? fromData?.identification_data?.other_reason
            : null,
      },
      ssn_data: {
        ...fromData?.ssn_data,
        other_reason:
          fromData?.ssn_data?.availableToUpload_ssn == "No" &&
            fromData?.ssn_data?.no_doc_reason == "other"
            ? fromData?.ssn_data?.other_reason
            : null,
      },
    };
    setOpen(false);
    if (
      getIdentificationData?.data?.identification_data?.Barcodedata[1]
        ?.field_value_original === "" &&
      getIdentificationData?.data?.identification_data?.Barcodedata[3]
        ?.field_value_original === "" &&
      getIdentificationData?.data?.identification_data?.Barcodedata[8]
        ?.field_value_original === ""
    ) {
       return onSubmitDetails(postData);
    }
    if (watch("identification_data.backfile") === null) {
      return onSubmitDetails(postData);
    }
    if (
      nameMatched?.applicantNameMatched === true &&
      nameMatched?.applicantDOBMatched === true
    ) {
      setOpen(false);
      return onSubmitDetails(postData);
    }

    if (
      nameMatched?.applicantNameMatched === false ||
      nameMatched?.applicantDOBMatched === false
    ) {
      //Beacuse we have to set both to not null but while for only DOB occur error, we are setting
      //selectDOB to "value={USERENTERED || DOCUMENTENTEREDVALUE}" but selectName remains null so
      //we use || not &&.
      if (selectedName !== null || selectedDOB !== null) {
        setOpen(false);
        return onSubmitDetails(postData);
      }
      console.log("6...");
      setOpen(true);
    } else {
      onSubmitDetails(postData);
    }
  };

  const handleSubmitValidation = async (parms, submit = true) => {
    if (parms === "personalDetails") {
      const personalDetailsschema = yup.object().shape({
        applicant: personaldetails_data_schema,
      });
      try {
        const result = await personalDetailsschema.validateSync(
          methods.getValues()
        );
        console.log("Result:954", result);
        if (!result) {
          if (submit) {
            submitBtnRef?.current?.click();
            dispatch(tabsStateAction.personalDetail(false));
          }
          //  dispatch(tabsStateAction.personalErr(true));
          return false;
        }
        return true;
      } catch (error) {
        console.error(
          "🚀 ~ file: identification-details.js ~ line 709 ~ handleChange ~ error",
          error
        );
      }
    } else if (parms === "ProofOfIdentity") {
      const localSchema = yup.object().shape({
        applicant: applicant_data_schema,
        identification_data: identification_data_schema,
      });
      try {
        const result = await localSchema.isValid(methods.getValues());
        if (!result) {
          if (submit) {
            submitBtnRef?.current?.click();
            dispatch(tabsStateAction.ProofOfIdentity(false));
          }
          //  dispatch(tabsStateAction.personalErr(true));
          return false;
        }
        return true;
      } catch (error) {
        console.error(
          "🚀 ~ file: identification-details.js ~ line 709 ~ handleChange ~ error",
          error
        );
      }
    } else if (parms === "Address") {
      const localSchema = yup.object().shape({
        address: address_data_schema,
      });
      try {
        const result = await localSchema.isValid(methods.getValues());
        if (!result) {
          if (!submit) return false;
          submitBtnRef?.current?.click();
          dispatch(tabsStateAction.Address(false));
          // dispatch(tabsStateAction.personalErr(true));
          return false;
        }
        return true;
      } catch (error) {
        console.error(
          "🚀 ~ file: identification-details.js ~ line 709 ~ handleChange ~ error",
          error
        );
      }
    } else if (parms === "SSN") {
      const localSchema = yup.object().shape({
        ssn_data: ssn_data_schema,
      });
      try {
        const result = await localSchema.isValid(methods.getValues());
        if (!result) {
          submitBtnRef?.current?.click();
          dispatch(tabsStateAction.Ssn(false));
          //   dispatch(tabsStateAction.personalErr(true));
          return false;
        }
        return true;
      } catch (error) {
        console.error(
          "🚀 ~ file: identification-details.js ~ line 709 ~ handleChange ~ error",
          error
        );
      }
    }
  };
  const handleChange = async (parms) => {
    // TODO
    // step 1 do validation then allow switch tabs
    // this is for mobile
    const personalDetailsschema = yup.object().shape({
      applicant: personaldetails_data_schema,
    });
    const validatePersonalDetails = await personalDetailsschema.isValid(methods.getValues());
    const localSchema = yup.object().shape({
      applicant: applicant_data_schema,
      identification_data: identification_data_schema,
    });
    const validateProofOfIdentity = await localSchema.isValid(methods.getValues());
    if (parms === "personalDetails") {
      const personalDetailsschema = yup.object().shape({
        applicant: personaldetails_data_schema,
      });
      try {
        const result = await personalDetailsschema.isValid(methods.getValues());
        if (result) {
          dispatch(tabsStateAction.personalDetail(true));
          dispatch(tabsStateAction.personalErr(false));
          methods.clearErrors();
        } else {
          submitBtnRef?.current?.click();
          dispatch(tabsStateAction.personalDetail(false));
          dispatch(tabsStateAction.personalErr(true));
        }
      } catch (error) {
        console.error(
          "🚀 ~ file: identification-details.js ~ line 709 ~ handleChange ~ error",
          error
        );
      }
    } else if (parms === "ProofOfIdentity") {
      const localSchema = yup.object().shape({
        applicant: applicant_data_schema,
        identification_data: identification_data_schema,
      });
      try {
        if (validatePersonalDetails) {
          const result = await localSchema.isValid(methods.getValues());
          if (result) {
            dispatch(tabsStateAction.ProofOfIdentity(true));
            dispatch(tabsStateAction.personalErr(false));
            methods.clearErrors();
          } else {
            submitBtnRef?.current?.click();
            dispatch(tabsStateAction.ProofOfIdentity(false));
            dispatch(tabsStateAction.personalErr(true));
          }
        } else {
          submitBtnRef?.current?.click();
          dispatch(tabsStateAction.personalDetail(false));
          dispatch(tabsStateAction.personalErr(true));
        }
      } catch (error) {
        console.error(
          "🚀 ~ file: identification-details.js ~ line 709 ~ handleChange ~ error",
          error
        );
      }
    } else if (parms === "Address") {
      const localSchema = yup.object().shape({
        address: address_data_schema,
      });
      try {
        if (validatePersonalDetails) {
          if (validateProofOfIdentity) {
            const result = await localSchema.isValid(methods.getValues());
            if (result) {
              dispatch(tabsStateAction.Address(true));
              dispatch(tabsStateAction.personalErr(false));
              methods.clearErrors();
            } else {
              submitBtnRef?.current?.click();
              dispatch(tabsStateAction.Address(false));
              dispatch(tabsStateAction.personalErr(true));
            }
          } else {
            submitBtnRef?.current?.click();
            dispatch(tabsStateAction.ProofOfIdentity(false));
            dispatch(tabsStateAction.personalErr(true));
          }
        } else {
          submitBtnRef?.current?.click();
          dispatch(tabsStateAction.personalDetail(false));
          dispatch(tabsStateAction.personalErr(true));
        }
      } catch (error) {
        console.error(
          "🚀 ~ file: identification-details.js ~ line 709 ~ handleChange ~ error",
          error
        );
      }
    }
  };

  const { hash } = location;
  

  useEffect(() => {
    if (!hash) return;
    if (hash == "#personal-details") {
      navigate(-1);
      return dispatch(tabsStateAction.personalDetail(false));
    }
    if (hash == "#poi") {
      navigate(-1);
      return handleChange("personalDetails");
    }
    if (hash == "#address") {
      navigate(-1);
      return handleChange("ProofOfIdentity");
    }
    if (hash == "#ssn") {
      navigate(-1);
      return handleChange("Address");
    }
  }, [hash]);

  if (getIdentificationData.isLoading && !!identificationWiseRules) return <h1>Loading</h1>;
  if (getIdentificationData.isError)
    return (
      <>
        <p>Something went wrong please try again</p>
      </>
    );
  return (
    <div id="identification-details-tabs">
      {/* <JSONTree data={{documentWiseRules,identificationWiseRules,paystubWiseRules}}/> */}
      <FormProvider {...methods}>
     
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <fieldset disabled={viewOnlyFlag}>
            {/* Here code  */}
            <div>
              <Accordion
                expanded={!personalDetailsStatus}
                onChange={() => {
                  dispatch(tabsStateAction.personalDetail(false));
                }}
              >
                <AccordionSummary
                  expandIcon={!personalDetailsStatus || <AiOutlinePlus />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography>
                    {personalDetailsStatus && <BsCheck2Circle color="green" />}{" "}
                    Personal Details
                  </Typography>
                </AccordionSummary>
                <PersonalDetails checkValidation={handleSubmitValidation} />
              </Accordion>

              <Accordion
                expanded={personalDetailsStatus && !ProofOfIdentityStatus}
                onChange={() => {
                  handleChange("personalDetails");
                }}
              >
                <AccordionSummary
                  expandIcon={
                    (personalDetailsStatus && !ProofOfIdentityStatus) || (
                      <AiOutlinePlus />
                    )
                  }
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography>
                    {ProofOfIdentityStatus && <BsCheck2Circle color="green" />}{" "}
                    Proof of Identity{" "}
                    <RiInformationLine
                      className="ms-2 cursor-pointer"
                      fill="#D3AF51"
                      size={20}
                      onClick={() => {
                        setLicenseInformation(true);
                      }}
                    />
                  </Typography>
                </AccordionSummary>
                <ProofOfIdentity
                  data={getIdentificationData?.data?.identification_data}
                  checkValidation={handleSubmitValidation}
                />
              </Accordion>
              <Accordion
                expanded={ProofOfIdentityStatus && !AddressStatus}
                onChange={() => {
                  handleChange("ProofOfIdentity");
                }}
              >
                <AccordionSummary
                  expandIcon={
                    (ProofOfIdentityStatus && !AddressStatus) || (
                      <AiOutlinePlus />
                    )
                  }
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography>
                    {AddressStatus && <BsCheck2Circle color="green" />} Address
                  </Typography>
                </AccordionSummary>
                <Address ProofOfIdentityStatus={ProofOfIdentityStatus} checkValidation={handleSubmitValidation} />
              </Accordion>
              <Accordion
                expanded={AddressStatus && !SsnStatus}
                onChange={() => {
                  handleChange("Address");
                }}
              >
                <AccordionSummary
                  expandIcon={
                    (AddressStatus && !SsnStatus) || <AiOutlinePlus />
                  }
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography>
                    {SsnStatus && <BsCheck2Circle color="green" />} Social
                    Security Number
                  </Typography>
                </AccordionSummary>
                <Ssn data={props?.data?.data?.records?.ssn_data} />
              </Accordion>
              <Snackbar
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                open={personalErr}
                key={1}
              >
                <Alert
                  onClick={() => {
                    dispatch(tabsStateAction.personalErr(false));
                  }}
                  severity="error"
                >
                  {" "}
                  Answer the required field <CloseIcon />
                </Alert>
              </Snackbar>
            </div>
            {/* end her  */}
            <button
              ref={submitBtnRef}
              style={{ display: "none" }}
              type="submit"
              className="btn btn-info"
            >
              NEXT
            </button>
          </fieldset>
          {open && (
            <Dialog
              maxWidth={"sm"}
              fullWidth={true}
              open={open}
              onClose={() => {
                setOpen(false);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle
                id="alert-dialog-title"
                style={{ color: "red", textAlign: "center" }}
              >
                <AiOutlineCloseCircle
                  onClick={() => {
                    setOpen(false);
                  }}
                  style={{ float: "left", color: "black" }}
                  className="cursor-pointer"
                />
                Important Alert!
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {nameMatched?.applicantNameMatched === false && (
                    <>
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <p>
                          Our system processed the document(s) you uploaded and
                          found one or more possible discrepancies. Please
                          review and indicate the correct information below.
                        </p>
                      </div>
                      <div>
                        {getIdentificationData?.data?.identification_data
                          ?.Barcodedata[1]?.field_value_original !== "" &&
                          getIdentificationData?.data?.identification_data
                            ?.Barcodedata[3]?.field_value_original !== "" && (
                            <>
                              <div className="col-sm-12 col-md-12 col-lg-12">
                                <>
                                  <p style={{ textAlign: "center" }}>
                                    <strong>
                                      Your document uploaded name mismatched:*
                                    </strong>
                                  </p>
                                  <p style={{ textAlign: "center" }}>
                                    Select the Correct Value
                                  </p>
                                </>
                              </div>
                              <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-6">
                                  <p>According to you:</p>
                                  <button
                                    className={`${selectedName === "USERENTEREDVALUE"
                                      ? "btn btn-warning btn-block"
                                      : "btn btn-block btn-outline-warning"
                                      }`}
                                    variant="contained"
                                    onClick={() => {
                                      setValue(
                                        "applicant.first_name",
                                        watch("applicant.first_name")
                                      );
                                      setValue(
                                        "applicant.last_name",
                                        watch("applicant.last_name")
                                      );
                                      setSelectedName("USERENTEREDVALUE");
                                      dispatch(
                                        sideNavAction.applicantNameMatched(
                                          false
                                        )
                                      );

                                      // setOpen(false);
                                      setTimeout(() => {
                                        // refSubmitButtom?.current?.click();
                                      }, 100);
                                    }}
                                  >
                                    <span>
                                      <span>
                                        {userFirstName.toUpperCase()}&nbsp;
                                        {userLastName.toUpperCase()}{" "}
                                      </span>
                                    </span>
                                  </button>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6">
                                  <p>According to your document:</p>
                                  <button
                                    className={`${selectedName === "DOCUMENTENTEREDVALUE"
                                      ? "btn btn-success btn-block"
                                      : "btn btn-block btn-outline-success"
                                      }`}
                                    variant="contained"
                                    onClick={() => {
                                      setValue(
                                        "applicant.first_name",
                                        getIdentificationData?.data
                                          ?.identification_data?.Barcodedata[1]
                                          ?.field_value_original
                                      );
                                      setValue(
                                        "applicant.last_name",
                                        getIdentificationData?.data
                                          ?.identification_data?.Barcodedata[3]
                                          ?.field_value_original
                                      );
                                      dispatch(
                                        sideNavAction.applicantNameMatched(true)
                                      );

                                      setSelectedName("DOCUMENTENTEREDVALUE");

                                      // setOpen(false);
                                      setTimeout(() => {
                                        // refSubmitButtom?.current?.click();
                                      }, 100);
                                    }}
                                  >
                                    <span>
                                      {
                                        getIdentificationData?.data
                                          ?.identification_data?.Barcodedata[1]
                                          ?.field_value_original
                                      }{" "}
                                      {
                                        getIdentificationData?.data
                                          ?.identification_data?.Barcodedata[3]
                                          ?.field_value_original
                                      }
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </>
                          )}
                      </div>
                    </>
                  )}
                </DialogContentText>
                <>
                  {getIdentificationData?.data?.identification_data
                    ?.Barcodedata[8]?.field_value_original !== "" && (
                      <>
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <div className="mt-2">
                            <p style={{ textAlign: "center" }}>
                              <strong>
                                Your document uploaded DOB mismatched:*
                              </strong>
                            </p>
                            <p style={{ textAlign: "center" }}>
                              Select the Correct Value
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-lg-6">
                            <p>According to you:</p>
                            <button
                              className={`${selectedDOB === "USERENTEREDVALUE"
                                ? "btn btn-warning btn-block"
                                : "btn btn-block btn-outline-warning"
                                }`}
                              variant="contained"
                              onClick={() => {
                                setValue("applicant.DOB", watch("applicant.DOB"));
                                // setOpen(false);
                                setSelectedDOB("USERENTEREDVALUE");
                                dispatch(
                                  sideNavAction.applicantDOBMatched(false)
                                );

                                setTimeout(() => {
                                  // refSubmitButtom?.current?.click();
                                }, 100);
                              }}
                            >
                              <span>
                                {/* {moment(watch("identification_data.DOB")).format(
                            "MM-DD-YYYY"
                          )} */}
                                {userDOB}
                              </span>
                            </button>
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-6">
                            <p>According to your document:</p>
                            <button
                              className={`${selectedDOB === "DOCUMENTENTEREDVALUE"
                                ? "btn btn-success btn-block"
                                : "btn btn-block btn-outline-success"
                                }`}
                              variant="contained"
                              onClick={() => {
                                //TODO: convert barcode DOB to Date format to submmit to API.
                                setValue(
                                  "applicant.DOB",
                                  getIdentificationData?.data?.identification_data
                                    ?.Barcodedata[8]?.field_value_original
                                );
                                dispatch(sideNavAction.applicantDOBMatched(true));
                                setSelectedDOB("DOCUMENTENTEREDVALUE");
                                // setOpen(false);
                              }}
                            >
                              <span>
                                {
                                  getIdentificationData?.data?.identification_data
                                    ?.Barcodedata[8]?.field_value_original
                                }
                              </span>
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                </>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setSelectedName(null);
                    setSelectedDOB(null);
                    setOpen(false);
                    setValue("applicant.first_name", userFirstName);
                    setValue("applicant.last_name", userLastName);
                    setValue("applicant.DOB", userDOB);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  // disabled={
                  //   selectedName === null || selectedDOB === null ? true : false
                  // }
                  autoFocus
                  onClick={() => {
                    setOpen(false);
                    submitBtnRef.current.click();
                  }}
                >
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </form>
        <>
          <Dialog
            open={licenseInformation}
            onClose={() => {
              setLicenseInformation(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title"></DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Please upload CLEAR and straight images of your proof of
                identification. For state driver’s licenses or ID’s, both the
                front and back of your ID are required, and the back barcode
                must be VERY CLEAR. If you don’t currently have a clear image(s)
                of your ID, then you can scan or click the scan ID QR code; this
                will allow you to use your mobile phone as a remote scanner.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={async () => {
                  setLicenseInformation(false);
                }}
                autoFocus
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </>
      </FormProvider>
      <DevTool control={methods.control} />
      <ToastContainer />
      <div className="mt-4">
        <SupportSection clickHere={clickHere} setClickHere={setClickHere} />
      </div>
    </div>
  );
};

export default IdentificationDetails;
